import { yupResolver } from '@hookform/resolvers/yup'
import { useGoogleLogin } from '@react-oauth/google'
import { get } from 'lodash-es'
import moment from 'moment'
import { darken } from 'polished'
import { useCallback, useEffect, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { Link, useNavigate } from 'react-router-dom'
import { useRecoilState, useRecoilValue } from 'recoil'
import styled from 'styled-components'
import * as yup from 'yup'

import AuthAlert from '@/Components/AuthAlert'
import { Button } from '@/Components/form/Buttons'
import Input from '@/Components/form/Input'
import InputError from '@/Components/form/InputError'
import { authAlertState, loginIntentState } from '@/Config/Atoms/Auth'
import useApiClient from '@/Utilities/useApiClient'
import useAuth from '@/Utilities/useAuth'
import useTitle from '@/Utilities/useTitle'

const Title = styled.h1`
  font-size: 30px;
  font-weight: 700;
`

const Form = styled.form`
  label {
    margin-top: 20px;
    color: #667085;
  }

  .buttons {
    margin-top: 40px;
  }
`

const SubText = styled.div`
  font-size: 16px;
  font-weight: 300;
  margin: 12px 0;
`

const GoogleButton = styled.a`
  align-items: center;
  background-color: white;
  border-radius: 5px;
  border: 1px solid var(--border-color);
  color: var(--text-dark);
  cursor: pointer;
  display: flex;
  font-size: 16px;
  justify-content: center;
  line-height: 42px;
  margin-top: 24px;
  padding: 0 20px;
  text-decoration: none;
  transition: all 0.2s ease;
  width: 100%;

  i {
    margin-right: 10px;
    background:
    linear-gradient(to bottom left,transparent 49%,#fbbc05 50%) 0 25%/48% 40%,
    linear-gradient(to top    left,transparent 49%,#fbbc05 50%) 0 75%/48% 40%,

    linear-gradient(-40deg ,transparent 53%,#ea4335 54%),
    linear-gradient( 45deg ,transparent 46%,#4285f4 48%),
    #34a853;
    background-repeat:no-repeat;
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    -webkit-text-fill-color: transparent;
  }

  &:hover {
    background-color: ${darken(0.2, '#ffffff')};
  }

  svg {
    margin-right: 12px;
  }
`

const schema = yup.object({
  email: yup.string().label('Email').email().required(),
  password: yup.string().label('Password').required(),
})

function Login() {
  const [alert, setAlert] = useRecoilState(authAlertState)
  const loginIntent = useRecoilValue(loginIntentState)
  const auth = useAuth()
  const apiClient = useApiClient()
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    formState,
  } = useForm({ resolver: yupResolver(schema) })
  const { errors } = formState
  const lastCsrfCookieRefresh = useRef()

  useTitle('Login')

  const handleCsrfCookie = useCallback(async () => {
    const timeNow = moment()
    const refreshTime = lastCsrfCookieRefresh.current

    if (!refreshTime || timeNow.diff(refreshTime, 'minutes') >= 5) {
      await apiClient.get('/sanctum/csrf-cookie')
      lastCsrfCookieRefresh.current = timeNow
    }
  }, [])

  useEffect(() => {
    // Add event listener and interval for when someone is AFK
    // Event listener is when the user refocuses the window
    // Interval is every 30 seconds which handles being on the page idle
    window.addEventListener('focus', handleCsrfCookie)

    const interval = setInterval(() => {
      handleCsrfCookie()
    }, 1000 * 30)

    // Run immediately
    handleCsrfCookie()

    // Cleanup
    return () => {
      window.removeEventListener('focus', handleCsrfCookie)
      clearInterval(interval)
    }
  }, [])

  useEffect(() => {
    if (loginIntent?.url) {
      setAlert({ message: 'You need to login to access this page.' })
    }
  }, [])

  const signIn = (data) => {
    setAlert(null)

    auth.signIn(data, (success, data) => {
      if (success) {
        if (get(data, 'user.termsAccepted')) {
          navigate('/dashboard')
        } else {
          navigate('/user/gate/terms-and-conditions')
        }
      } else {
        setAlert({ message: 'The provided credentials do not match our records.' })
      }
    })
  }

  const googleSignIn = (response) => {
    auth.googleSignIn(response.access_token, (success, data) => {
      if (success) {
        if (get(data, 'user.termsAccepted')) {
          navigate('/dashboard')
        } else {
          navigate('/user/gate/terms-and-conditions')
        }
      } else {
        setAlert({ message: 'The provided credentials do not match our records.' })
      }
    })
  }

  const responseGoogle = (response) => {
    if (response?.error === 'idpiframe_initialization_failed') {
      return false
    }

    setAlert({ message: 'We were unable to log you in with Google.' })
  }

  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => {
      return googleSignIn(tokenResponse)
    },
    onError: (tokenResponse) => {
      return responseGoogle(tokenResponse)
    },
  })

  return (
    <div>
      <Form onSubmit={handleSubmit(signIn)} autoComplete="off">
        <Title className="text-black">Log in</Title>
        <SubText className="text-slate-500">Welcome Back! Please enter your details.</SubText>

        <AuthAlert class={alert ? 'flex' : 'd-none'} type={alert?.type ? alert.type : null} message={alert?.message}/>

        <Input
          label="Email"
          type="email"
          placeholder="Enter your email"
          autoComplete="username"
          className={errors.email && 'error'}
          {...register('email')}
        />
        {errors.email && <InputError message={errors.email.message}/>}

        <Input
          data-sentry-mask
          label="Password"
          type="password"
          placeholder="Enter your password"
          autoComplete="current-password"
          className={errors.password && 'error'}
          {...register('password')}
        />
        {errors.password && <InputError message={errors.password.message}/>}

        <div className="my-6 text-center text-slate-500">
          Forgot your password? <Link to="/user/forgot-password" className="text-primary">Reset it now</Link>.
        </div>

        <Button
          style={{ width: '100%' }}
        >
          Sign in
        </Button>

        <div className="mt-3 text-base">
          <GoogleButton onClick={login}>
            <i className="fa-brands fa-google"></i> Sign in with Google
          </GoogleButton>
        </div>

        <div className="mt-10 justify-center text-center text-slate-500">
          Use of FutureOps is subject to the Branif Systems <Link to="#" className="text-primary">terms of use</Link>.
        </div>
      </Form>
    </div>
  )
}

export default Login
