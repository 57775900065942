import { get } from 'lodash-es'

import CurrentDateTime from '@/Components/common/CurrentDateAndTime'

/**
 * Renders the current date and time for a specific site with optional timezone abbreviation display.
 *
 * @param {Object} params - The parameters for the method.
 * @param {Object} params.site - The site object containing the time data.
 * @param {boolean} [params.showAbbreviation=true] - Optional flag to show timezone abbreviation.
 *
 * @return {JSX.Element} The component displaying the current date and time with optional timezone abbreviation.
 */
export default function SiteTime({
  site,
  showAbbreviation = true,
  multiLineInMobile = false,
}) {
  return (
    <CurrentDateTime
      initialTime={get(site, 'siteTime.date.utc')}
      timezone={get(site, 'timeZone')}
      showAbbreviation={showAbbreviation}
      multiLineInMobile={multiLineInMobile}
    />
  )
}
