import { ArcElement,
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  DoughnutController,
  Filler,
  LinearScale,
  LineController,
  LineElement,
  PieController,
  PointElement,
  TimeScale,
  Title,
  Tooltip } from 'chart.js'
import ZoomPlugin from 'chartjs-plugin-zoom'
import { debounce, filter, findIndex, first, flatten, forEach, get, isEmpty, last, map, round, toNumber, values } from 'lodash-es'
import moment from 'moment'
import { darken, transparentize } from 'polished'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'
import { v4 } from 'uuid'

import ActionIcon from '@/Components/ActionIcon'
import DropdownList from '@/Components/DropdownList'
import Legend from '@/Components/reporting/Legend'
import { modalState } from '@/Config/Atoms/General'
import { lttbDownsample } from '@/Utilities/Array'

const dataSetColours = [
  '#1570ef',
  '#ff5733',
  '#f7b500',
  '#e63946',
  '#ff6f61',
  '#9c27b0',
  '#29b6f6',
  '#ff9800',
  '#673ab7',
  '#ff1493',
]

const annotationColours = [
  '#2A6041',
  '#00BD9D',
  '#006655',
  '#449C6A',
  '#9BC53D',
  '#2CF6B3',
  '#006C67',
  '#ADE25D',
  '#94FBAB',
  '#3DDC97',
]

export default function Chart(props) {
  const {
    originalChart,
    id,
    name,
    data,
    refreshActiveDashboard,
  } = props

  const [hasZoomed, setHasZoomed] = useState(false)
  const setModal = useSetRecoilState(modalState)
  const [urlSearchParams] = useSearchParams()

  const chartInstanceRef = useRef()
  const chartElementRef = useRef()
  const chartInit = useRef(false)

  const minStart = useRef()
  const maxEnd = useRef()

  const registerChartPlugins = useCallback(() => {
    ChartJS.register(
      ArcElement,
      BarController,
      BarElement,
      CategoryScale,
      DoughnutController,
      LineController,
      LineElement,
      LinearScale,
      PieController,
      PointElement,
      TimeScale,
      Title,
      Tooltip,
      ZoomPlugin,
      Filler,
    )
  }, [])

  const getNumberOfPoints = useCallback((type) => {
    let numberOfPoints = 100

    if (type === 'bar') {
      numberOfPoints = 100
    }

    if (chartInstanceRef.current) {
      const basePointsPerPixel = numberOfPoints / 850 // 850 is the max width of the chart
      const chartArea = chartInstanceRef.current?.chartArea

      if (chartArea.width <= 600) {
        numberOfPoints = round(chartArea.width * basePointsPerPixel)
      }
    }

    return numberOfPoints
  }, [])

  const getColoursDataSets = useCallback((dataSets) => {
    const colouredDataSets = map(dataSets, (dataSet, index) => {
      const colours = dataSet.yAxisID === 'yDataSets' ? dataSetColours : annotationColours
      const colour = colours[index % colours.length]

      return {
        ...dataSet,
        backgroundColor: dataSet.type === 'line' ? darken(0.2, colour) : colour,
        borderColor: colour,
      }
    })

    return colouredDataSets
  }, [])

  const updateChartColours = useCallback(() => {
    const {
      ctx,
      chartArea,
    } = chartInstanceRef.current

    const colouredDataSets = map(chartInstanceRef.current.data.datasets, (dataSet, index) => {
      let colours = annotationColours
      let topOpacityAdjustment = 0.6
      let bottomOpacityAdjustment = 1

      if (dataSet.yAxisID === 'yDataSets') {
        colours = dataSetColours
        topOpacityAdjustment = 0.1
        bottomOpacityAdjustment = 0.9
      }

      const colour = colours[index % colours.length]

      const fillColourTop = transparentize(topOpacityAdjustment, colour)
      const fillColourBottom = transparentize(bottomOpacityAdjustment, colour)

      const gradientAbove = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top)
      gradientAbove.addColorStop(1, fillColourTop)
      gradientAbove.addColorStop(0, fillColourBottom)

      const gradientBelow = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top)
      gradientBelow.addColorStop(0, fillColourTop)
      gradientBelow.addColorStop(1, fillColourBottom)

      return {
        ...dataSet,
        backgroundColor: dataSet.type === 'line' ? darken(0.2, colour) : colour,
        borderColor: colour,
        fill: {
          target: 'origin',
          above: gradientAbove,
          below: gradientBelow,
        },
      }
    })

    chartInstanceRef.current.data.datasets = colouredDataSets
    chartInstanceRef.current.stop()
    chartInstanceRef.current.update('none')
  }, [])

  const getTimeOptions = useCallback((hourDifference) => {
    if (hourDifference < 6) {
      return { unit: 'minute' }
    } if (hourDifference > 6 && hourDifference < 3 * 24) {
      return { unit: 'hour' }
    } else if (hourDifference >= 3 * 24 && hourDifference < 30 * 24) {
      return { unit: 'day' }
    } else if (hourDifference >= 30 * 24 && hourDifference < 90 * 24) {
      return { unit: 'week' }
    } else if (hourDifference >= 90 * 24) {
      return { unit: 'month' }
    }

    return {}
  }, [])

  const setTimeOptions = useCallback((scaleX) => {
    const {
      min,
      max,
    } = scaleX
    const hourDifference = (max - min) / (1000 * 60 * 60)
    const timeOptions = getTimeOptions(hourDifference)

    chartInstanceRef.current.options.scales.x.time.unit = timeOptions.unit
  }, [])

  const setAdjustedDataSet = useCallback((scaleX) => {
    let {
      min = null,
      max = null,
    } = scaleX

    const newAnnotations = map(data.annotations, (annotation) => {
      return {
        ...annotation,
        data: createAnnotationSteps(annotation.data),
      }
    })

    const allDataSets = [...values(data.dataSets), ...newAnnotations]

    forEach(chartInstanceRef.current.data.datasets, (oldDataSet, index) => {
      let dataSet = allDataSets[index].data

      if (min && max) {
        dataSet = filter(allDataSets[index].data, (data) => {
          return data.x >= min && data.x <= max
        })
      }

      if (oldDataSet.yAxisID === 'yAnnotations') {
        const lastAnnotation = last(dataSet)

        // Create a "fake" ending point for the annotation to span
        // the entire chart width if the last point is on
        if (lastAnnotation && lastAnnotation.y === 1) {
          dataSet = [
            ...dataSet,
            {
              x: max,
              y: 1,
              metadata: { invisible: true },
            },
            {
              x: max,
              y: 0,
              metadata: { invisible: true },
            },
          ]

          chartInstanceRef.current.data.datasets[index].data = dataSet
        }
      }

      if (oldDataSet.yAxisID === 'yDataSets') {
        const numberOfPoints = getNumberOfPoints(oldDataSet.type)
        const sampledData = lttbDownsample(dataSet, numberOfPoints, 'x', 'y')

        const firstPoint = first(sampledData)
        const lastPoint = last(sampledData)

        if (firstPoint && lastPoint) {
          const totalDuration = lastPoint.x - firstPoint.x

          if (totalDuration > 0) {
            sampledData.push({
              x: last(sampledData).x + (totalDuration / 100 * 15),
              y: null,
            })
          }
        }

        chartInstanceRef.current.data.datasets[index].data = sampledData
      }
    })
  }, [])

  const chartDates = useMemo(() => {
    let startDate = moment().subtract(7, 'day')
    let endDate = moment()

    if (urlSearchParams.get('startDate')) {
      startDate = moment(urlSearchParams.get('startDate'))
    }

    if (urlSearchParams.get('endDate')) {
      endDate = moment(urlSearchParams.get('endDate'))
    }

    return {
      startDate: startDate,
      endDate: endDate,
    }
  }, [urlSearchParams])

  const dataSets = useMemo(() => {
    if (!isEmpty(data.dataSets)) {
      return map(data.dataSets, (dataSet) => {
        const numberOfPoints = getNumberOfPoints(dataSet.type)
        const sampledData = lttbDownsample(dataSet.data, numberOfPoints, 'x', 'y')

        return {
          uuid: v4(),
          label: dataSet.label,
          data: sampledData,
          tension: 0.05,
          yAxisID: 'yDataSets',
          type: dataSet.type,
        }
      })
    }

    return []
  }, [])

  const createAnnotationSteps = useCallback((annotationValues) => {
    let lastValue = null

    const steppedAnnotation = map(annotationValues, (data) => {
      let steps = []

      let shouldStep = lastValue !== data.y

      if (shouldStep) {
        steps = [{
          ...data,
          y: toNumber(!data.y),
        }, {
          ...data,
          y: toNumber(data.y),
        }]
      } else {
        steps = [{
          ...data,
          y: toNumber(data.y),
        }]
      }

      lastValue = data.y

      return steps
    })

    return flatten(steppedAnnotation)
  }, [])

  const annotations = useMemo(() => {
    if (!isEmpty(data.annotations)) {
      return map(data.annotations, (annotation) => {
        return {
          uuid: v4(),
          label: annotation.label,
          data: createAnnotationSteps(annotation.data),
          borderWidth: 2,
          yAxisID: 'yAnnotations',
          pointRadius: 0,
          pointHoverRadius: 0,
          tooltip: false,
          type: 'line',
          segment: {
            borderColor: (ctx) => {
              // This is to ensure that the line is not visible when the annotation
              // is not yet off and we're "faking" it until the next off payload is received
              const firstPointInvisible = get(ctx, 'p0.raw.metadata.invisible', false)
              const secondPointInvisible = get(ctx, 'p1.raw.metadata.invisible', false)

              if (firstPointInvisible && secondPointInvisible) {
                return 'rgba(0, 0, 0, 0)' // Transparent
              }

              return undefined // Default
            },
          },
        }
      })
    }

    return []
  }, [])

  const getOptions = useCallback(() => {
    const {
      startDate,
      endDate,
    } = chartDates

    const hourDifference = endDate.diff(startDate, 'hours')

    const timeOptions = getTimeOptions(hourDifference)

    return {
      scales: {
        x: {
          type: 'time',
          adapters: { date: { zone: 'UTC' } },
          time: {
            tooltipFormat: 'MMM dd, yyyy HH:mm:ss',
            displayFormats: {
              minute: 'MMM dd, HH:mm',
              hour: 'MMM dd, HH a',
              day: 'MMM dd, yyyy',
              week: 'MMM dd, yyyy',
              month: 'MMM dd, yyyy',
            },
            ...timeOptions,
          },
          title: {
            display: true,
            text: 'Date',
          },
          ticks: {
            autoSkip: true,
            maxTicksLimit: 10,
          },
          bounds: 'data',
        },
        yAnnotations: {
          type: 'linear',
          position: 'right',
          display: true,
          grid: { drawOnChartArea: false },
          ticks: {
            min: 0,
            max: 1,
            stepSize: 1,
            callback: function(value) {
              if (value === 1) {
                return 'On'
              }

              if (value === 0) {
                return 'Off'
              }

              return value
            },
          },
        },
        yDataSets: {
          type: 'linear',
          position: 'left',
          title: {
            display: true,
            text: 'Value',
          },
          ticks: { beginAtZero: true },
        },
      },
      responsive: true,
      plugins: {
        tooltip: {
          mode: 'nearest',
          intersect: true,
          callbacks: {
            label: function(context) {
              if (context.dataset.tooltip === false) {
                return null
              }

              return ` ${context.dataset.label}: ${context.formattedValue}`
            },
          },
        },
        zoom: {
          pan: {
            enabled: true,
            modifierKey: 'ctrl',
            mode: 'x',
            onPanStart({ chart }) {
              // Stop animation on pan
              chart.stop()
            },
            onPanComplete: ({ chart }) => {
              setTimeOptions(chart.scales.x)
              setAdjustedDataSet(chart.scales.x)

              // Stop animation and update chart
              chart.stop()
              chart.update()
            },
          },
          zoom: {
            mode: 'x',
            overScaleMode: 'x',
            drag: { enabled: true },
            wheel: { enabled: false },
            pinch: { enabled: true },
            limits: {
              x: {
                min: 'original',
                max: 'original',
              },
              yDataSets: {
                min: 'original',
                max: 'original',
              },
              yAnnotations: {
                min: 0,
                max: 1,
              },
            },
            onZoom: ({ chart }) => {
              setTimeOptions(chart.scales.x)
              setAdjustedDataSet(chart.scales.x)

              // Stop animation and update chart
              chart.stop()
              chart.update()

              setHasZoomed(true)
            },
          },
        },
      },
      interaction: {
        mode: 'nearest',
        axis: 'x',
        intersect: false,
      },
    }
  }, [])

  const handleResponsiveLabels = useCallback(() => {
    if (window.innerWidth <= 600) {
      chartInstanceRef.current.options.scales.x.ticks.display = false
      chartInstanceRef.current.options.scales.yDataSets.ticks.display = false
      chartInstanceRef.current.options.scales.yAnnotations.ticks.display = false
    } else {
      chartInstanceRef.current.options.scales.x.ticks.display = true
      chartInstanceRef.current.options.scales.yDataSets.ticks.display = true
      chartInstanceRef.current.options.scales.yAnnotations.ticks.display = true
    }
  }, [])

  const toggleDataSet = useCallback((uuid) => {
    const chart = chartInstanceRef.current
    const index = findIndex(chart.data.datasets, { uuid: uuid })
    const dataSet = chart.data.datasets[index]

    if (!isEmpty(dataSet)) {
      dataSet.hidden = !dataSet.hidden
    }

    chart.stop()
    chart.update('none')
  }, [])

  const resetZoom = useCallback(() => {
    if (chartInstanceRef.current) {
      chartInstanceRef.current.resetZoom()

      const startDate = moment(minStart.current)
      const endDate = moment(maxEnd.current)
      const hourDifference = endDate.diff(startDate, 'hours')

      const timeOptions = getTimeOptions(hourDifference)

      chartInstanceRef.current.options.scales.x.time.unit = timeOptions.unit

      setAdjustedDataSet({
        min: minStart.current,
        max: maxEnd.current,
      })

      // Stop animation
      chartInstanceRef.current.stop()

      // Update chart without animation
      chartInstanceRef.current.update('none')

      setHasZoomed(false)
    }
  }, [])

  useEffect(() => {
    registerChartPlugins()

    const updateChartData = (chart) => {
      if (!chartInit.current) {
        return
      }

      setAdjustedDataSet(chart.scales.x)
      updateChartColours()
      handleResponsiveLabels()
    }

    const options = getOptions()

    chartInstanceRef.current = new ChartJS(chartElementRef.current, {
      options: {
        ...options,
        onResize: debounce((chart) => {
          updateChartData(chart)
        }, 200, {
          leading: true,
          trailing: true,
        }),
      },
      data: { datasets: [...dataSets, ...annotations] },
    })

    const {
      startDate,
      endDate,
    } = chartDates

    // Set the min/max bounds
    minStart.current = get(chartInstanceRef.current, 'scales.x.min', startDate.valueOf())
    maxEnd.current = get(chartInstanceRef.current, 'scales.x.max', endDate.valueOf())

    chartInit.current = true

    updateChartData(chartInstanceRef.current)

    // Event handlers
    let isMouseDown = false

    const mouseDown = () => {
      isMouseDown = true
    }

    const mouseMove = debounce(() => {
      if (isMouseDown) {
        chartInstanceRef.current.options.plugins.tooltip.enabled = false
        chartInstanceRef.current.stop()
        chartInstanceRef.current.update('none')
      }
    }, 100, {
      leading: true,
      trailing: false,
    })

    const mouseUpAndOut = () => {
      isMouseDown = false
      chartInstanceRef.current.options.plugins.tooltip.enabled = true
    }

    if (chartElementRef.current) {
      chartElementRef.current.addEventListener('mousedown', mouseDown)
      chartElementRef.current.addEventListener('mousemove', mouseMove)
      chartElementRef.current.addEventListener('mouseup', mouseUpAndOut)
      chartElementRef.current.addEventListener('mouseout', mouseUpAndOut)
    }

    // Cleanup
    return () => {
      if (chartElementRef.current) {
        chartElementRef.current.removeEventListener('mousedown', mouseDown)
        chartElementRef.current.removeEventListener('mousemove', mouseMove)
        chartElementRef.current.removeEventListener('mouseup', mouseUpAndOut)
        chartElementRef.current.removeEventListener('mouseout', mouseUpAndOut)
      }

      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy()
      }

      setHasZoomed(false)
    }
  }, [])

  return (
    <div className="w-full">
      <div className="absolute right-4 top-4">
        <div className="grid grid-cols-2 items-end">
          <div className="text-center">
            {hasZoomed && (
              <a
                className="cursor-pointer text-slate-600 hover:text-slate-400"
                onClick={() => {
                  resetZoom()
                }}
              >
                <i className="fa-solid fa-magnifying-glass-minus"></i>
              </a>
            )}
          </div>
          <div>
            <DropdownList
              icon={<ActionIcon />}
              wrapperStyle={{ display: 'inline-flex' }}
              style={{
                minWidth: '200px',
                textAlign: 'left',
              }}
              options={[
                {
                  label: 'Edit chart',
                  onClick: () => {
                    setModal({
                      name: 'chart',
                      data: {
                        chart: {
                          ...originalChart,
                          isEditing: true,
                        },
                        onSave: () => {
                          refreshActiveDashboard()
                        },
                      },
                    })
                  },
                },
                {
                  label: 'Delete chart',
                  onClick: () => {
                    setModal({
                      name: 'warning',
                      data: {
                        title: 'Delete chart',
                        content: `Please note that ${name} and all associated chart data will be permanently removed from the system.`,
                        endpoint: `/chart/delete/${id}`,
                        successFlashMessage: `${name} deleted successfully.`,
                        errorFlashMessage: 'An error occured and we were unable to delete this chart. Please try again',
                        onComplete: () => {
                          refreshActiveDashboard()
                        },
                      },
                    })
                  },
                },
                {
                  label: 'Reset zoom',
                  onClick: () => {
                    resetZoom()
                  },
                },
              ]}
            />
          </div>
        </div>
      </div>

      <div className="pb-2 text-center text-sm font-medium">
        {name}
      </div>

      <div className="pb-2 text-center">
        <Legend
          toggleDataSet={toggleDataSet}
          toggleAnnotation={toggleDataSet}
          dataSets={getColoursDataSets(dataSets)}
          annotations={getColoursDataSets(annotations)}
        />
      </div>

      <div className="aspect-4/2">
        <canvas ref={chartElementRef}></canvas>

        {isEmpty(dataSets) && isEmpty(annotations) && (
          <div className="flex flex-col items-center justify-center">
            <div>
              <i className="fa-kit fa-sharp-regular-chart-column-slash fa-8x text-slate-300"></i>
            </div>

            <div className="mt-5 text-center text-slate-600">
              Data unavailable. <br />
              Please check your chart configuration or filters applied.
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
