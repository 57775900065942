import { each, filter, get, head, isEmpty, map, toNumber } from 'lodash-es'
import React, { useEffect, useMemo, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import Input from '@/Components/form/Input'
import InputError from '@/Components/form/InputError'
import LightSwitch from '@/Components/form/LightSwitch'
import Select from '@/Components/form/Select'
import { unitOptions } from '@/Utilities/Units'
import useApiClient from '@/Utilities/useApiClient'
import useTitle from '@/Utilities/useTitle'

export default function General(props) {
  const apiClient = useApiClient()
  const [irrigationBlockOptions, setIrrigationBlockOptions] = useState([])
  const [irrigationBlocksLoading, setIrrigationBlocksLoading] = useState(null)

  const { setSiteTime } = props

  const {
    control,
    register,
    errors,
    watch,
    setValue,
  } = useFormContext()

  const isEditing = props.data?.isEditing || false

  const selectedSiteId = watch('siteId')
  const setDisabled = watch('disabled')

  const set = useMemo(() => {
    let set = null

    if (props.data?.programSet) {
      set = props.data?.programSet
    }

    return set
  }, [props.data?.programSet])

  useTitle([`${set ? 'Edit' : 'Add'} program set`, set?.name])

  const blocks = useMemo(() => {
    if (set?.blocks) {
      let blocks = []

      each(set.blocks, (block) => {
        blocks.push({
          value: block.id,
          label: block.title,
        })
      })

      return blocks
    }

    return null
  }, [])

  const stabilizationTimeMeasurementOptions = useMemo(() => {
    return unitOptions('stabilisation')
  }, [])

  useEffect(() => {
    (async () => {
      if (selectedSiteId?.value) {
        setIrrigationBlocksLoading(true)

        let { data } = await apiClient.get(`/site/view/${selectedSiteId.value}?with[]=blocks`)

        if (!isEmpty(data?.site)) {
          setSiteTime({
            siteTime: get(data, 'site.site_time', {}),
            timeZone: get(data, 'site.time_zone'),
          })

          const availableBlocks = map(data.site.blocks, (block) => {
            return {
              value: block.id,
              label: block.title,
            }
          })

          setIrrigationBlockOptions(availableBlocks)
        }

        setIrrigationBlocksLoading(false)
      }
    })()
  }, [selectedSiteId])

  useEffect(() => {
    const currentTab = document.getElementById('general')

    if (currentTab) {
      props.setTabErrors((prevState) => {
        return {
          ...prevState,
          general: !!currentTab?.querySelector('.error-message'),
        }
      })
    }
  }, [errors, props.setTabErrors])

  return (
    <>
      {
        !props.setupLoading && props.siteOptions &&
        <>
          <Controller
            control={control}
            name="siteId"
            render={({ field }) => {
              return (
                <Select
                  {...field}
                  isMulti={false}
                  isSearchable={true}
                  label="Site"
                  isRequired={true}
                  isLoading={props.setupLoading}
                  options={props.siteOptions}
                  placeholder="Search"
                  hasError={!!errors.siteId}
                  isDisabled={isEditing}
                />
              )
            }}
          />
          {errors.siteId && <InputError message={errors.siteId.message} />}
        </>
      }

      <Input
        label="Name"
        isRequired={true}
        type="text"
        className={errors.name && 'error'}
        {...register('name', { value: set?.name })}
      />
      {errors.name && <InputError message={errors.name.message} />}

      <Input
        label="Description"
        type="text"
        className={errors.description && 'error'}
        {...register('description', { value: set?.description })}
      />
      {errors.description && <InputError message={errors.description.message} />}

      {(selectedSiteId || blocks) && (
        <>
          <Controller
            control={control}
            defaultValue={blocks || ''}
            name="blocks"
            render={({ field }) => {
              return (
                <Select
                  {...field}
                  isMulti={true}
                  isSearchable={true}
                  label="Irrigation blocks"
                  isLoading={irrigationBlocksLoading}
                  options={irrigationBlockOptions}
                  placeholder="Search"
                  hasError={!!errors.blocks}
                  noOptionsMessage={() => {
                    return selectedSiteId ? 'There are no options for the selected site.' : 'Select a site to see available options.'
                  }}
                />
              )
            }}
          />
          {errors.irrigationBlocks && <InputError message={errors.irrigationBlocks.message} />}
        </>
      )}

      <Input
        label="Consecutive flow failure tolerance"
        isRequired={true}
        type="number"
        className={errors.flowFailureTolerance && 'error'}
        {...register('flowFailureTolerance', { value: set?.flowFailureTolerance })}
      />
      {errors.flowFailureTolerance && <InputError message={errors.flowFailureTolerance.message} />}

      <div className="row">
        <div className="col-12 col-md-6">
          <Controller
            control={control}
            defaultValue={head(filter(stabilizationTimeMeasurementOptions, ['value', set?.stabilizationTimeUnitOfMeasurement]))}
            name="stabilizationTimeUnitOfMeasurement"
            render={({ field }) => {
              return (
                <Select
                  {...field}
                  isMulti={false}
                  isSearchable={true}
                  label="Flow stability time unit of measure"
                  isRequired={true}
                  options={stabilizationTimeMeasurementOptions}
                  placeholder="Search"
                  hasError={!!errors.stabilizationTimeUnitOfMeasurement}
                />
              )
            }}
          />
          {errors.stabilizationTimeUnitOfMeasurement && <InputError message={errors.stabilizationTimeUnitOfMeasurement.message} />}
        </div>

        <div className="col-12 col-md-6">
          <Input
            label="Flow stability time"
            isRequired={true}
            type="number"
            className={errors.stabilizationTime && 'error'}
            {...register('stabilizationTime', { value: set?.stabilizationTime })}
          />
          {errors.stabilizationTime && <InputError message={errors.stabilizationTime.message} />}
        </div>
      </div>

      <div className="mt-8">
        <LightSwitch
          label="Disable program set"
          onToggle={(name, value) => {
            setValue('disabled', value)
          }}
          defaultState={toNumber(set?.disabled) ? true : setDisabled || false}
          {...register('disabled', { value: toNumber(set?.disabled) || false })}
        />
      </div>
    </>
  )
}
