import '@/Utilities/Form/Yup'

import { yupResolver } from '@hookform/resolvers/yup'
import * as Sentry from '@sentry/react'
import { each, find, flatMap, get, isEmpty, isFunction, isUndefined, padStart, pick } from 'lodash-es'
import { useEffect, useMemo, useRef, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useRecoilState, useSetRecoilState } from 'recoil'
import styled from 'styled-components'

import AlertContent from '@/Components/alerts/AlertContent'
import DropdownList from '@/Components/DropdownList'
import { Anchor, Button } from '@/Components/form/Buttons'
import Modal from '@/Components/Modal'
import CommonFields from '@/Components/modals/inputOutput/CommonFields'
import ConditionalFields from '@/Components/modals/inputOutput/ConditionalFields'
import { isLoadingState, pageAlertState } from '@/Config/Atoms/General'
import { getInputOutputsTypes } from '@/Utilities/Accessors/InputOutputs'
import { publish } from '@/Utilities/Events'
import { flattenSelectValues, formatKeys } from '@/Utilities/Form/Formatter'
import sleep from '@/Utilities/Sleep'
import useApiClient from '@/Utilities/useApiClient'
import useEntityMonitor from '@/Utilities/useEntityMonitor'
import useTitle from '@/Utilities/useTitle'
import inputOutputSchema from '@/Utilities/Validation/InputOutputSchema'

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 28px;
`

const ButtonGroup = styled.div`
  display: flex;
  width: calc(50% - 5px);
`

const Form = styled.form`
  label {
    margin-top: 20px;
  }

  .buttons {
    margin-top: 40px;
  }
`

const ioTypeFieldMap = {
  delta: ['delta', 'deltaUnitOfMeasurement'],
  reportingTime: ['reportingTimeMinutes', 'reportingTimeSeconds'],
  stabilization: ['stabilizationTime', 'stabilizationTimeUnitOfMeasurement'],
  scale: [
    'scaleMax',
    'scaleMin',
    'scaleUnitOfMeasurement',
  ],
  scalePulsesPerHour: ['scaleMinPulsesPerHour', 'scaleMaxPulsesPerHour'],
  rate: ['rate', 'rateUnitOfMeasurement'],
  pulseValue: ['pulseValue', 'pulseValueUnitOfMeasurement'],
  onDelay: ['onDelay', 'onDelayUnitOfMeasurement'],
  offDelay: ['offDelay', 'offDelayUnitOfMeasurement'],
  area: ['areaCoverage', 'areaCoverageUnitOfMeasurement'],
  manufacturer: ['manufacturerModelId'],
}

const getFields = (additionalFields = []) => {
  const commonFields = [
    'changeOfState',
    'mapLinking',
    'physicalConnection',
  ]

  return [...commonFields, ...additionalFields]
}

const ioTypes = [
  {
    key: 'inputOutput.analogInput',
    sections: getFields([
      'delta',
      'reportingTime',
      'scale',
      'stabilization',
      'logging',
    ]),
  },
  {
    key: 'inputOutput.analogOutput',
    sections: getFields([
      'auxiliary',
      'delta',
      'reportingTime',
      'scale',
      'logging',
    ]),
  },
  {
    key: 'inputOutput.analogFlowMeter',
    sections: getFields([
      'auxiliary',
      'delta',
      'reportingTime',
      'scale',
      'stabilization',
      'logging',
    ]),
  },
  {
    key: 'inputOutput.digitalFlowMeter',
    sections: getFields([
      'auxiliary',
      'delta',
      'pulseValue',
      'reportingTime',
      'stabilization',
      'logging',
    ]),
  },
  {
    key: 'inputOutput.digitalInput',
    sections: getFields(['offDelay', 'onDelay']),
  },
  {
    key: 'inputOutput.digitalOutputBooster',
    sections: getFields([
      'auxiliary',
      'offDelay',
      'onDelay',
    ]),
  },
  {
    key: 'inputOutput.digitalOutputElectricLock',
    sections: getFields([
      'auxiliary',
      'offDelay',
      'onDelay',
    ]),
  },
  {
    key: 'inputOutput.digitalOutputFountain',
    sections: getFields([
      'auxiliary',
      'offDelay',
      'onDelay',
    ]),
  },
  {
    key: 'inputOutput.digitalOutputGeneral',
    sections: getFields([
      'auxiliary',
      'offDelay',
      'onDelay',
    ]),
  },
  {
    key: 'inputOutput.digitalOutputLight',
    sections: getFields([
      'auxiliary',
      'offDelay',
      'onDelay',
    ]),
  },
  {
    key: 'inputOutput.digitalOutputValve',
    sections: getFields([
      'area',
      'auxiliary',
      'rate',
    ]),
  },
  {
    key: 'inputOutput.virtualDigitalOutput',
    sections: [],
  },
  {
    key: 'inputOutput.sensorSoilProbe',
    sections: getFields([
      'manufacturer',
      'delta',
      'reportingTime',
      'logging',
    ]),
  },
  {
    key: 'inputOutput.sensorGeneralDigital',
    sections: getFields([
      'scale',
      'scalePulsesPerHour',
      'delta',
      'reportingTime',
      'logging',
    ]),
  },
]

function InputOutputModal(props) {
  const apiClient = useApiClient()
  const [inputOutputTypeOptions, setInputOutputTypeOptions] = useState()
  const [isLoading, setIsLoading] = useRecoilState(isLoadingState)
  const [setupLoading, setSetupLoading] = useState(true)
  const [isEditingOverride, setIsEditingOverride] = useState(false)
  const form = useRef(null)
  const setAlert = useSetRecoilState(pageAlertState)

  const {
    isInRunningState,
    getRelatedProgramNames,
  } = useEntityMonitor()

  const isEditing = isEditingOverride ? !isEditingOverride : props.data?.isEditing || isEditingOverride

  const inputOutput = useMemo(() => {
    return props.data?.io
  }, [props.data?.io])

  const preventSaving = isInRunningState('inputOutput', inputOutput?.id)
  const runningProgramNames = getRelatedProgramNames('inputOutput', inputOutput?.id)

  useTitle([`${inputOutput ? 'Edit' : 'Add'} I/O`, inputOutput?.name])

  const loggingTypeOptions = useMemo(() => {
    return [{
      value: 'interval',
      label: 'Interval',
    }, {
      value: 'delta',
      label: 'Delta',
    }]
  }, [])

  const {
    trigger,
    reset,
    watch,
    setValue,
    getValues,
    register,
    handleSubmit,
    control,
    formState,
  } = useForm({
    resolver: yupResolver(inputOutputSchema),
    defaultValues: {
      siteId: props.data?.site ? {
        value: props.data.site.id,
        label: props.data.site.name,
      } : null,
      changeOfState: !isUndefined(inputOutput?.changeOfState) ? !!inputOutput?.changeOfState : true,
      auxiliary: !isUndefined(inputOutput?.auxiliary) ? !!inputOutput?.auxiliary : false,
      logging: !isUndefined(inputOutput?.logging) ? !!inputOutput.logging : false,
      loggingType: find(loggingTypeOptions, ['value', inputOutput?.loggingType]) || null,
    },
  })

  const {
    errors,
    dirtyFields,
  } = formState

  const selectedIoType = watch('type')
  const loggingEnabled = watch('logging')
  const mapLinkingEnabled = watch('mapLinking')

  useEffect(() => {
    if (!isEditing && loggingEnabled) {
      setValue('loggingType', find(loggingTypeOptions, ['value', 'interval']))
    }
  }, [isEditing, loggingEnabled])

  useEffect(() => {
    (async () => {
      const types = await getInputOutputsTypes(apiClient)

      const flatTypes = flatMap(types, (type) => {
        if (isEmpty(type.options)) {
          return type
        }

        return type.options
      })

      setValue('type', find(flatTypes, ['value', inputOutput?.detailsType]))

      setInputOutputTypeOptions(types)
      setSetupLoading(false)
    })()
  }, [])

  const onSubmit = async (data, afterSave = null) => {
    setAlert(null)
    setIsLoading(true)

    data = flattenSelectValues(data)

    const sections = get(find(ioTypes, ['key', selectedIoType?.value]), 'sections', [])
    const fields = flatMap(pick(ioTypeFieldMap, sections))

    data.details = flattenSelectValues(pick(data.details, fields))

    data.details.reportingTime = data.details.reportingTimeMinutes || data.details.reportingTimeSeconds ? `00:${padStart(data.details.reportingTimeMinutes, 2, 0)}:${padStart(data.details.reportingTimeSeconds, 2, 0)}` : null,
    data.loggingScheduledTime = data.loggingScheduledTimeHours || data.loggingScheduledTimeMinutes ? `${padStart(data.loggingScheduledTimeHours, 2, 0)}:${padStart(data.loggingScheduledTimeMinutes, 2, 0)}:00` : null,
    data.loggingInterval = data.loggingIntervalHours || data.loggingIntervalMinutes || data.loggingIntervalSeconds ? `${padStart(data.loggingIntervalHours, 2, 0)}:${padStart(data.loggingIntervalMinutes, 2, 0)}:${padStart(data.loggingIntervalSeconds, 2, 0)}` : null,

    delete data.details.reportingTimeMinutes
    delete data.details.reportingTimeSeconds

    if (!data.details.reportingTime) {
      delete data.details.reportingTime
    }

    data = formatKeys(data, 'snake')

    try {
      let { data: responseData } = await apiClient.post(`/input-output/${isEditing ? `update/${props.data.io.id}`: 'create'} `, data)

      if (responseData.success) {
        if (isFunction(props.data?.onSave)) {
          props.data.onSave(responseData.inputOutput, mapLinkingEnabled)
        }

        setAlert({
          type: 'info',
          content: `Attempting to ${isEditing ? 'update' : 'create'} I/O ${responseData.inputOutput.name}.`,
        })

        const submit = await afterSave
        publish('inputOutputCreated')

        if (!isUndefined(submit)) {
          props.close()
        }
      }
    } catch (error) {
      setAlert({
        type: 'error',
        content: 'An error has occured and we were not able to save this IO. Please try again.',
      })
      Sentry.captureException(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Modal
      title={`${isEditing ? 'Edit': 'Add'} I/O`}
      close={props.close}
      closeOnOutsideClick={props.closeOnOutsideClick}
    >
      {setupLoading ?
        (
          <div className="flex h-80 items-center justify-center">
            <div className="primary-loader"></div>
          </div>
        ) : (
          <FormProvider
            control={control}
            dirtyFields={dirtyFields}
            errors={errors}
            getValues={getValues}
            handleSubmit={handleSubmit}
            register={register}
            reset={reset}
            setValue={setValue}
            trigger={trigger}
            watch={watch}
          >
            {preventSaving && (
              <AlertContent className="mb-4">
                You cannot edit or delete this as the following programs are running: {runningProgramNames}
              </AlertContent>
            )}

            <Form onSubmit={handleSubmit(onSubmit)} autoComplete="off" ref={form}>
              {inputOutputTypeOptions && (
                <>
                  <CommonFields
                    {...props}
                    inputOutputTypeOptions={inputOutputTypeOptions}
                    setValue={setValue}
                  />

                  <ConditionalFields
                    inputOutput={inputOutput}
                    isEditing={isEditing}
                    details={inputOutput?.details}
                    activeSections={get(find(ioTypes, ['key', selectedIoType?.value]), 'sections', [])}
                    ioType={selectedIoType?.value}
                  />
                </>
              )}

              <ButtonWrapper className="buttons">
                <Anchor
                  style={{ width: 'calc(50% - 5px)' }}
                  className="transparent"
                  onClick={() => {
                    setAlert(null)
                    reset()
                    props.close()
                  }}
                >
                  Close
                </Anchor>

                <ButtonGroup>
                  <Button
                    disabled={isLoading ? true : false}
                    disabledWithReason={preventSaving && runningProgramNames}
                    style={!mapLinkingEnabled ? {
                      borderTopRightRadius: 0,
                      borderBottomRightRadius: 0,
                      flexGrow: 1,
                    } : { flexGrow: 1 }}
                  >
                    {isLoading ? <div className="primary-loader light"></div> : 'Save'}
                  </Button>

                  {(!mapLinkingEnabled && !preventSaving) && (
                    <DropdownList
                      disabled={isLoading ? true : false}
                      wrapperStyle={{ display: 'flex' }}
                      style={{ transform: 'translate(0px, -100px)' }}
                      icon={
                        <Anchor
                          disabled={isLoading ? true : false}
                          style={{
                            borderTopLeftRadius: 0,
                            borderBottomLeftRadius: 0,
                            borderLeft: '1px solid rgba(255, 255, 255, 0.4)',
                          }}
                        >
                          <i className="fa-solid fa-caret-down"></i>
                        </Anchor>
                      }
                      options={[{
                        label: 'Save & Duplicate',
                        onClick: () => {
                          let afterSave = async (formValues) => {
                            reset()
                            setSetupLoading(true)
                            setIsEditingOverride(true)

                            each(formValues, (value, key) => {
                              switch (key) {
                                case 'name':
                                case 'description':
                                case 'moduleSlot':
                                case 'channel':
                                  setValue(key, '')
                                  break

                                default:
                                  setValue(key, value)
                                  break
                              }
                            })

                            await sleep(3000)
                            trigger()
                            setSetupLoading(false)
                          }

                          handleSubmit((data) => {
                            return onSubmit(data, afterSave(data))
                          })()
                        },
                      }]}
                      list={true}
                    />
                  )}
                </ButtonGroup>
              </ButtonWrapper>
            </Form>
          </FormProvider>
        )
      }
    </Modal>
  )
}

export default InputOutputModal
