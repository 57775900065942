import { concat, debounce, head, map, slice } from 'lodash-es'
import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'
import styled from 'styled-components'

import ActionIcon from '@/Components/ActionIcon'
import TableHeader from '@/Components/common/table/Header'
import TypeSearch from '@/Components/common/TypeSearch'
import DropdownList from '@/Components/DropdownList'
import HUISelect from '@/Components/headless/form/Select'
import Pill from '@/Components/pill/Pill'
import PillWrapper from '@/Components/pill/PillWrapper'
import SkeletonTable from '@/Components/SkeletonTable'
import TableBasic from '@/Components/tables/base/Basic'
import Tabs from '@/Components/Tabs'
import { modalState } from '@/Config/Atoms/General'
import { formattedDateTime } from '@/Utilities/DateTime'
import useApiClient from '@/Utilities/useApiClient'
import useAuth from '@/Utilities/useAuth'

const Name = styled.div`
  color: var(--text-dark);
  font-weight: bold;
  margin-bottom: 5px;
`
export default forwardRef((props, ref) => {
  const apiClient = useApiClient()
  const setModal = useSetRecoilState(modalState)
  const navigate = useNavigate()
  const auth = useAuth()

  const [tableData, setTableData] = useState(null)
  const [tableDataLoading, setTableDataLoading] = useState(true)
  const [tableSearchTerm, setTableSearchTerm] = useState({
    type: null,
    value: null,
  })

  const [selectedTab, setSelectedTab] = useState(null)

  const getTableData = useMemo(() => {
    return debounce(async ({
      pageIndex,
      pageSize,
      filters,
    }) => {
      const query = new URLSearchParams([
        ['page', pageIndex + 1],
        ['pageSize', pageSize],
        ['searchType', filters?.search?.type || ''],
        ['search', filters?.search?.value || ''],
        ['filter', filters?.tab || ''],
        ['with[]', 'roles'],
        ['with[]', 'areas'],
      ])

      const { data } = await apiClient.get(`/user/query?${query}`)

      let userData = map(data.users.data, (user) => {
        return {
          name: <><Name>{user.name}</Name> {user.email}</>,
          dateJoined: formattedDateTime(user.created_at) || '-',
          role: head(user.roles)?.name,
          areas: (
            user.areas &&
          <PillWrapper>
            {map(user.areas, (area, index, arr) => {
              if (index < 2) {
                return (
                  <Pill color="#344054" key={index}>
                    {area.name}
                  </Pill>
                )
              } else if (index + 1 === arr.length) {
                return <Pill color={'#1570ef'} key={index}>{`+${arr.length - 2}`}</Pill>
              }
            })}
          </PillWrapper>
          ),
          action: auth.can('manage-organization-users') ? (
            <DropdownList
              icon={<ActionIcon />}
              options={[{
                label: 'Manage user',
                onClick: () => {
                  navigate(`/admin/user/manage/${user.id}`)
                },
                disabled: !auth.can('manage-organization-users'),
              }, {
                label: 'Remove user',
                onClick: () => {
                  setModal({
                    name: 'warning',
                    data: {
                      endpoint: `/user/delete/${user.id}`,
                      title: 'Remove user',
                      content: `Are you sure you want to remove ${user.name}? This action cannot be undone.`,
                      successFlashMessage: `${user.name} has been successfully removed.`,
                      errorFlashMessage: 'There was a problem removing the user. Please try again.',
                      redirect: '/admin/organization',
                      onComplete: () => {
                        setModal(null)
                        getTableData({
                          pageSize: 15,
                          pageIndex: 0,
                        })
                      },
                    },
                  })
                },
                disabled: !auth.can('delete-users'),
              }]}
            />
          ) : '',
        }
      })

      let tableData = {
        ...data.users,
        data: userData,
      }

      setTableData(tableData)
      setTableDataLoading(false)
    }, 250)
  }, [
    setTableData,
    setTableDataLoading,
    navigate,
    setModal,
  ])

  useImperativeHandle(ref, () => {
    return {
      refresh: () => {
        getTableData({
          pageSize: 15,
          pageIndex: 0,
        })
      },
    }
  })

  useEffect(() => {
    getTableData({
      pageSize: 15,
      pageIndex: 0,
    })
  }, [getTableData])

  const getTableDataStart = useCallback((params) => {
    setTableDataLoading(true)
    getTableData(params)
  }, [getTableData, setTableDataLoading])

  const tableColumns = useMemo(
    () => {
      return [
        {
          Header: 'Name',
          accessor: 'name',
          width: '10%',
        },
        {
          Header: 'Role',
          accessor: 'role',
          width: '10%',
        },
        {
          Header: 'Date joined',
          accessor: 'dateJoined',
          width: '25%',
        },
        {
          Header: 'Areas',
          accessor: 'areas',
          width: '10%',
        },
        {
          Header: '',
          accessor: 'action',
          width: '5%',
        },
      ]
    }, [],
  )

  const filterTabs = useMemo(() => {
    const tabItems = [
      {
        title: 'View all',
        key: null,
      },
      {
        title: 'Admins',
        key: 'administrator',
      },
      {
        title: 'Operators',
        key: 'operator',
      },
      {
        title: 'Managers',
        key: 'manager',
      },
      {
        title: 'Viewers',
        key: 'viewer',
      },
    ]

    const superAdminItem = {
      title: 'Super Admins',
      key: 'super-administrator',
    }

    if (auth.isSuperAdmin) {
      return concat(
        slice(tabItems, 0, 1),
        superAdminItem,
        slice(tabItems, 1),
      )
    }

    return tabItems
  }, [auth])

  const searchTypes = useMemo(() => {
    return [{
      value: 'user',
      label: 'User',
    }, {
      value: 'area',
      label: 'Area',
    }]
  }, [])

  return (
    <>
      {
        tableData ?
          <TableBasic
            testId="tableUsersAll"
            mainTable={true}
            columns={tableColumns}
            data={tableData}
            loading={tableDataLoading}
            getTableData={getTableDataStart}
            searchTypes={searchTypes}
            searchTerm={tableSearchTerm}
            selectedTab={selectedTab}
            header={
              <TableHeader testId="tableAdminUsersAll">
                <TableHeader.Title>Team</TableHeader.Title>

                <TableHeader.Pills className="mt-2 justify-self-start md:mt-0 md:ml-3 md:inline-block md:justify-self-start">
                  <PillWrapper>
                    <Pill color="#175CD3">{`${tableData.total} total`}</Pill>
                  </PillWrapper>
                </TableHeader.Pills>

                <TableHeader.SubHeader className="flex flex-col justify-between gap-3 px-3 @md:px-6 @lg:flex-row @lg:items-center">
                  <div className="hidden md:inline-block">
                    <Tabs
                      tabs={filterTabs}
                      onChange={(selected) => {
                        if (selected !== selectedTab) {
                          setSelectedTab(selected)
                        }
                      }}
                      className="hidden md:inline-flex"
                    />
                  </div>

                  <div className="block md:hidden">
                    <HUISelect
                      className="mt-3 w-full rounded-md"
                      defaultValue={head(filterTabs)}
                      labelProperty="title"
                      options={filterTabs}
                      onChange={(selected) => {
                        if (selected.key !== selectedTab) {
                          setSelectedTab(selected.key)
                        }
                      }}
                    />
                  </div>

                  <TableHeader.Toolbar className="grid grid-flow-row gap-0 md:grid-flow-col">
                    <TypeSearch
                      types={searchTypes}
                      search={tableSearchTerm}
                      onChange={(searchTerm) => {
                        return setTableSearchTerm(searchTerm)
                      }}
                    />
                  </TableHeader.Toolbar>
                </TableHeader.SubHeader>
              </TableHeader>
            }
          /> : <SkeletonTable />
      }
    </>
  )
})
