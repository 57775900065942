import Tippy from '@tippyjs/react'
import classNames from 'classnames'
import { isEmpty } from 'lodash-es'
import React, { useEffect, useState } from 'react'
import { twMerge } from 'tailwind-merge'

import ActionIcon from '@/Components/ActionIcon'
import PermissionGuard from '@/Components/auth/PermissionGuard'
import DropdownList from '@/Components/DropdownList'
import { iconClasses } from '@/Utilities/Icons/InputOutputIcons'
import useAuth from '@/Utilities/useAuth'

export default function InputOutputCardListItem(props) {
  const {
    inputOutput,
    programActiveStatus,
    inProgram,
    changeOfState,
    type,
    stateChangeOptions,
  } = props
  const [isFlickering, setIsFlickering] = useState(false)
  const [displayedChangeOfState, setDisplayedChangeOfState] = useState(changeOfState)

  const auth = useAuth()

  useEffect(() => {
    if (changeOfState !== displayedChangeOfState) {
      setIsFlickering(true)

      setTimeout(() => {
        setIsFlickering(false)
        setDisplayedChangeOfState(changeOfState)
      }, 1500)
    }
  }, [changeOfState, displayedChangeOfState])

  return (
    <div
      key={inputOutput.id}
      className={twMerge(classNames(
        'flex justify-between border-b p-3.5 ',
        {
          'bg-status-queued/10': programActiveStatus === 'running' && inProgram,
          'bg-status-running/10': displayedChangeOfState && isEmpty(inputOutput.activeAlarms),
          'bg-status-alarm/10': !isEmpty(inputOutput.activeAlarms),
          'animate-flicker': isFlickering,
        },
      ))}
    >
      <div className="flex flex-1 items-center text-sm">
        <div
          className={twMerge(classNames(
            'mr-5 inline-block w-6 cursor-pointer text-center opacity-80 text-slate-500',
            {
              'text-status-queued': programActiveStatus === 'running' && inProgram,
              'text-status-running': displayedChangeOfState && isEmpty(inputOutput.activeAlarms),
              'text-status-alarm': !isEmpty(inputOutput.activeAlarms),
            },
          ))}
        >
          <i className={classNames(iconClasses[type], 'text-lg')}></i>
        </div>

        <div>
          {inputOutput.name}
        </div>
      </div>

      <div
        className={classNames(
          'mr-3 flex items-center justify-end text-sm',
          { 'text-status-running': displayedChangeOfState && isEmpty(inputOutput.activeAlarms) })
        }
      >
        {inputOutput.manuallyRun && (
          <Tippy content="This output has been manually run" theme="light">
            <i className="fa-solid fa-hand mr-3 text-lg"></i>
          </Tippy>
        )}

        {(displayedChangeOfState && isEmpty(inputOutput.activeAlarms)) && (
          <>
            <div className="mr-1.5 h-1.5 w-1.5 rounded-full bg-status-running"></div>

            <span>
              Active
            </span>
          </>
        )}

        {!isEmpty(inputOutput.activeAlarms) && (
          <>
            <div className="mr-1.5 h-1.5 w-1.5 rounded-full bg-status-alarm"></div>

            <span className="text-status-alarm">
              Alarm
            </span>
          </>
        )}

        {inProgram && (isEmpty(inputOutput.activeAlarms) && !displayedChangeOfState) && (
          <>
            <div className="mr-1.5 h-1.5 w-1.5 rounded-full bg-status-queued"></div>

            <span className="text-status-queued">
              Assigned
            </span>
          </>
        )}
      </div>

      <div className="flex items-center justify-end text-sm">
        <PermissionGuard anyOf={[
          'update-input-output',
          'view-input-output',
          'start-input-output',
          'stop-input-output',
        ]} auth={auth}>
          <DropdownList
            icon={<ActionIcon />}
            options={stateChangeOptions}
          />
        </PermissionGuard>
      </div>
    </div>
  )
}
