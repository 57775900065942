import { isFunction } from 'lodash-es'
import moment from 'moment-timezone'
import React, { useEffect, useState } from 'react'

export default function CurrentDateTime({
  initialTime,
  timezone,
  showAbbreviation = true,
  multiLineInMobile = false,
  setTime,
}) {
  // State to hold the current dateTime
  const [dateTime, setDateTime] = useState(moment.tz(initialTime, timezone))

  // Effect hook to set up the interval timer
  useEffect(() => {
    // Function to update the state with the current dateTime
    const updateCurrentTime = () => {
      const currentTime = moment.tz(timezone)

      setDateTime(currentTime)

      if (isFunction(setTime)) {
        setTime(currentTime)
      }
    }

    // Set up an interval to update the dateTime every second
    const intervalId = setInterval(updateCurrentTime, 1000)

    // Clean up the interval on component unmount
    return () => {
      return clearInterval(intervalId)
    }
  }, [timezone, setTime])

  // Render the current date and dateTime in a readable format
  return multiLineInMobile ? (
    <div>
      <div className="md:inline">{dateTime.format('MM-DD-YYYY')}</div>
      <span className="mx-1 hidden md:inline">/</span>
      <div className="md:inline">
        {dateTime.format('HH:mm:ss')}
        {showAbbreviation && (
          <span className="ml-1">{dateTime.format('z')}</span>
        )}
      </div>
    </div>
  ) : (
    <>
      <span>{dateTime.format('MM-DD-YYYY / HH:mm:ss')}</span>
      {showAbbreviation && (
        <span className="ml-1">{dateTime.format('z')}</span>
      )}
    </>
  )
}
