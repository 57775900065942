import classNames from 'classnames'
import { find, first, get, isBoolean, split } from 'lodash-es'
import { useCallback, useEffect, useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import styled from 'styled-components'

import AlertContent from '@/Components/alerts/AlertContent'
import DayPicker from '@/Components/form/DayPicker'
import Input from '@/Components/form/Input'
import InputError from '@/Components/form/InputError'
import InputLabel from '@/Components/form/InputLabel'
import LightSwitch from '@/Components/form/LightSwitch'
import RequiredAsterisk from '@/Components/form/RequiredAsterisk'
import Select from '@/Components/form/Select'

const GroupedInputs = styled.div`
  align-items: center;
  display: flex;

  input {
    margin-right: 20px;
  }
`

const TimeSeperator = styled.div`
  margin: 0 10px 0 -10px;
`

export default function Timing(props) {
  const {
    control,
    watch,
    setValue,
    trigger,
    register,
    errors,
    dirtyFields,
    getValues,
  } = useFormContext()

  const scheduleType = watch('scheduleType')
  const schedule = watch('schedule')
  const stopTimeDisabled = watch('stopTimeDisabled')
  const infiniteCycles = watch('infiniteCycles')
  const numberOfCycles = watch('numberOfCycles')
  const selectedIrrigationBasis = watch('irrigationBasis')

  let programSet

  if (props.data?.programSet) {
    programSet = {
      ...props.data?.programSet,
      schedule: props.data?.programSet?.schedule,
    }
  }

  const { scheduleTypeOptions } = props

  const times = useMemo(() => {
    if (programSet) {
      return {
        startTime: split(programSet.startTime, ':'),
        stopTime: split(programSet.stopTime, ':'),
        intervalBetweenCycles: split(programSet.intervalBetweenCycles, ':'),
      }
    }

    return null
  }, [programSet])

  const irrigationBasisOptions = useMemo(() => {
    return [{
      value: 'time',
      label: 'Time',
    }, {
      value: 'quantity',
      label: 'Quantity',
    }]
  }, [])

  const getBooleanValue = useCallback((programSet, key, defaultValue = true) => {
    if (isBoolean(programSet?.[key])) {
      return programSet[key]
    }

    return defaultValue
  }, [])

  useEffect(() => {
    const currentTab = document.getElementById('timing')

    if (currentTab) {
      props.setTabErrors((prevState) => {
        return {
          ...prevState,
          timing: !!currentTab?.querySelector('.error-message'),
        }
      })
    }
  }, [errors, props.setTabErrors])

  useEffect(() => {
    if (numberOfCycles == 1) {
      setValue('intervalBetweenCyclesHours', null)
      setValue('intervalBetweenCyclesMinutes', null)
      setValue('intervalBetweenCyclesSeconds', null)
    }
  }, [numberOfCycles])

  return (
    <>
      <Controller
        control={control}
        defaultValue={find(scheduleTypeOptions, ['value', programSet?.scheduleType]) || first(scheduleTypeOptions)}
        name="scheduleType"
        render={({ field }) => {
          return (
            <Select
              {...field}
              isMulti={false}
              isSearchable={false}
              label="Schedule type"
              isRequired={true}
              options={scheduleTypeOptions}
              placeholder="Search"
              afterChange={(option) => {
                if (option.value === 'interval') {
                  setValue('schedule', null)
                }

                if (option.value === 'days') {
                  setValue('intervalBetweenIrrigationDays', null)
                }
              }}
              hasError={!!errors.scheduleType}
            />
          )
        }}
      />
      {errors.scheduleType && <InputError message={errors.scheduleType.message} />}

      <div className={classNames({ hidden: scheduleType?.value != 'interval' })}>
        <Input
          label="Interval between irrigation days"
          type="number"
          className={errors.intervalBetweenIrrigationDays && 'error'}
          {...register('intervalBetweenIrrigationDays', { value: programSet?.intervalBetweenIrrigationDays })}
          min="1"
          max="100"
        />
        {errors.intervalBetweenIrrigationDays && <InputError message={errors.intervalBetweenIrrigationDays.message} />}
      </div>

      <div className={classNames({ hidden: scheduleType?.value != 'days' })}>
        <InputLabel>
          Schedule
          <RequiredAsterisk />
        </InputLabel>

        <div className="rounded border p-6">
          <DayPicker
            name="schedule"
            placeholder="Select irrigation days"
            onChange={(schedule, shouldValidate = false) => {
              setValue('schedule', schedule, { shouldValidate: shouldValidate })
            }}
            rules={{ required: true }}
            indexCount={4}
            externalRef={schedule}
            initialSchedule={programSet?.schedule || getValues('schedule')}
            className="max-w-96"
          />
        </div>
        {errors.schedule && <InputError message={errors.schedule.message} />}
      </div>

      <div className="row">
        <div className="col-12 col-md-4">
          <InputLabel>
            Start time
            <RequiredAsterisk />
          </InputLabel>

          <GroupedInputs>
            <Input
              placeholder="hh"
              maxlength="2"
              type="number"
              width="60px"
              textCenter
              className={errors.startTimeHours && 'error'}
              {...register('startTimeHours', {
                value: times?.startTime[0] || '',
                onChange: () => {
                  if (dirtyFields.stopTimeHours || dirtyFields.stopTimeMinutes) {
                    trigger('stopTimeHours')
                    trigger('stopTimeMinutes')
                  }
                },
              })}
            />

            <TimeSeperator>
              :
            </TimeSeperator>

            <Input
              placeholder="mm"
              maxlength="2"
              type="number"
              width="60px"
              textCenter
              className={errors.startTimeMinutes && 'error'}
              {...register('startTimeMinutes', {
                value: times?.startTime[1] || '',
                onChange: () => {
                  if (dirtyFields.stopTimeHours || dirtyFields.stopTimeMinutes) {
                    trigger('stopTimeHours')
                    trigger('stopTimeMinutes')
                  }
                },
              })}
            />
          </GroupedInputs>

          {
            (
              (
                errors.startTimeHours?.type === 'min' ||
                errors.startTimeHours?.type === 'max' ||
                errors.startTimeMinutes?.type === 'min' ||
                errors.startTimeMinutes?.type === 'max'
              ) &&
              <InputError message="Start time should be between 00:00 and 23:59" />
            ) ||
            (
              (errors.startTimeHours || errors.startTimeMinutes) &&
              <InputError message="Start time is a required field" />
            )
          }
        </div>

        <div className="col-12 col-md-5">
          <InputLabel>
            Stop time
            {!stopTimeDisabled && <RequiredAsterisk />}
          </InputLabel>

          <div className="grid grid-cols-2">
            <div>
              <GroupedInputs>
                <Input
                  placeholder="hh"
                  maxlength="2"
                  type="number"
                  width="60px"
                  textCenter
                  disabled={stopTimeDisabled}
                  className={errors.stopTimeHours && 'error'}
                  {...register('stopTimeHours', {
                    value: !getBooleanValue(programSet, 'stopTimeDisabled') ? get(times, 'stopTime[0]', '') : '',
                    onChange: () => {
                      if (dirtyFields.stopTimeMinutes) {
                        trigger('stopTimeMinutes')
                      }
                    },
                  })}
                />

                <TimeSeperator>
                  :
                </TimeSeperator>

                <Input
                  placeholder="mm"
                  maxlength="2"
                  type="number"
                  width="60px"
                  textCenter
                  disabled={stopTimeDisabled}
                  className={errors.stopTimeMinutes && 'error'}
                  {...register('stopTimeMinutes', {
                    value: !getBooleanValue(programSet, 'stopTimeDisabled') ? get(times, 'stopTime[1]', '') : '',
                    onChange: () => {
                      if (dirtyFields.stopTimeHours) {
                        trigger('stopTimeHours')
                      }
                    },
                  })}
                />
              </GroupedInputs>

              {
                (
                  (
                    errors.stopTimeHours?.type === 'min' ||
                    errors.stopTimeHours?.type === 'max' ||
                    errors.stopTimeMinutes?.type === 'min' ||
                    errors.stopTimeMinutes?.type === 'max'
                  ) &&
                  <InputError message="Stop time should be between 00:00 and 23:59" />
                ) ||
                (
                  (errors.stopTimeHours?.type === 'stopAfterStart' || errors.stopTimeMinutes?.type === 'stopAfterStart') &&
                  <InputError message="Stop time must be after start time" />
                ) ||
                (
                  (errors.stopTimeHours || errors.stopTimeMinutes) &&
                  <InputError message="Stop time is a required field" />
                )
              }
            </div>

            <div className="ml-4 flex items-start">
              <LightSwitch
                wrapperClassNames="mt-2"
                label="Disable"
                onToggle={(name, value) => {
                  setValue(name, value)

                  if (value) {
                    setValue('stopTimeHours', '', { shouldValidate: true })
                    setValue('stopTimeMinutes', '', { shouldValidate: true })
                  }
                }}
                defaultState={getBooleanValue(programSet, 'stopTimeDisabled')}
                {...register('stopTimeDisabled', { value: getBooleanValue(programSet, 'stopTimeDisabled') })}
              />
            </div>
          </div>
        </div>
      </div>

      <Controller
        control={control}
        defaultValue={find(irrigationBasisOptions, ['value', programSet?.irrigationBasis])}
        name="irrigationBasis"
        render={({ field }) => {
          return (
            <Select
              {...field}
              isMulti={false}
              isSearchable={true}
              label="Irrigation basis"
              isRequired={true}
              options={irrigationBasisOptions}
              placeholder="Search"
              hasError={!!errors.irrigationBasis}
            />
          )
        }}
      />
      {errors.irrigationBasis && <InputError message={errors.irrigationBasis.message} />}

      {(programSet?.irrigationBasis && programSet?.irrigationBasis != selectedIrrigationBasis?.value) && (
        <AlertContent type="warning" hideIcon={false} className="mt-2">
          By changing the irrigation basis, this will affect all programs that belong to this program set.
          Configuration will be required on each program in this program set, in addition, the program will be disabled.
          Once you've updated the required fields on each program, you can re-enable the program to resume operation.
        </AlertContent>
      )}

      <div className="grid grid-cols-2">
        <div>
          <Input
            label="Number of cycles"
            isRequired={!infiniteCycles ? true : false}
            type="number"
            className={errors.numberOfCycles && 'error'}
            min="1"
            disabled={infiniteCycles}
            {...register('numberOfCycles', { value: programSet ? programSet?.numberOfCycles : 1 })}
          />
          {errors.numberOfCycles && <InputError message={errors.numberOfCycles.message} />}
        </div>

        <div className="ml-4 flex items-start">
          <LightSwitch
            wrapperClassNames="mt-14"
            label="Infinite Cycles"
            onToggle={(name, value) => {
              setValue(name, value)

              const numberOfCycles = value ? null : 1
              setValue('numberOfCycles', numberOfCycles, { shouldValidate: true })
              setValue('intervalBetweenCyclesHours', null, { shouldValidate: true })
              setValue('intervalBetweenCyclesMinutes', null, { shouldValidate: true })
              setValue('intervalBetweenCyclesSeconds', null, { shouldValidate: true })
            }}
            defaultState={getBooleanValue(programSet, 'infiniteCycles', false)}
            {...register('infiniteCycles', { value: getBooleanValue(programSet, 'infiniteCycles', false) })}
          />
        </div>
      </div>

      <div className={classNames('col-12 col-md-6', { hidden: (numberOfCycles == 1 || !numberOfCycles || infiniteCycles) })}>
        <InputLabel>
          Interval between cycles
          <RequiredAsterisk />
        </InputLabel>

        <GroupedInputs>
          <Input
            placeholder="hh"
            maxlength="2"
            type="number"
            width="60px"
            textCenter
            className={errors.intervalBetweenCyclesHours && 'error'}
            {...register('intervalBetweenCyclesHours', { value: times?.intervalBetweenCycles[0] || '' })}
          />

          <TimeSeperator>
            :
          </TimeSeperator>

          <Input
            placeholder="mm"
            maxlength="2"
            type="number"
            width="60px"
            textCenter
            className={errors.intervalBetweenCyclesMinutes && 'error'}
            {...register('intervalBetweenCyclesMinutes', { value: times?.intervalBetweenCycles[1] || '' })}
          />

          <TimeSeperator>
            :
          </TimeSeperator>

          <Input
            placeholder="ss"
            maxlength="2"
            type="number"
            width="60px"
            textCenter
            className={errors.intervalBetweenCyclesSeconds && 'error'}
            {...register('intervalBetweenCyclesSeconds', { value: times?.intervalBetweenCycles[2] || '' })}
          />
        </GroupedInputs>

        {
          (
            (
              errors.intervalBetweenCyclesHours?.type === 'min' ||
              errors.intervalBetweenCyclesHours?.type === 'max' ||
              errors.intervalBetweenCyclesMinutes?.type === 'min' ||
              errors.intervalBetweenCyclesMinutes?.type === 'max' ||
              errors.intervalBetweenCyclesSeconds?.type === 'min' ||
              errors.intervalBetweenCyclesSeconds?.type === 'max'
            ) &&
            <InputError message="Interval between cycles should be between 00:00 and 23:59" />
          ) ||
          (
            (errors.intervalBetweenCyclesHours || errors.intervalBetweenCyclesMinutes || errors.intervalBetweenCyclesSeconds) &&
            <InputError message="Interval between cycles is a required field" />
          )
        }
      </div>
    </>
  )
}
