import { get } from 'lodash-es'
import moment from 'moment'
import { useCallback, useEffect, useMemo, useRef } from 'react'

export default function useProgramTimer({
  identifyingKey,
  identifyingId,
  displayed,
  setSecondsRemaining,
  status = '',
  setTimerOutOfSyncWarning,
}) {
  let intervalId = useRef(null)
  let noStopInterval = useRef(null)

  const clearTimer = useCallback(() => {
    if (intervalId.current) {
      clearInterval(intervalId.current)
      intervalId.current = null
    }
  }, [])

  const calculateRuntime = useCallback((options) => {
    const timing = options.displayed.timing

    if (!timing) {
      return false
    }

    const remainingRuntime = timing.totalSeconds - timing.elapsedSeconds

    return remainingRuntime
  }, [])

  const createTimer = useCallback((options) => {
    let remainingRuntimeTimer = calculateRuntime(options)
    options.set(remainingRuntimeTimer)

    if (remainingRuntimeTimer) {
      if (intervalId.current) {
        clearTimer()
      }

      intervalId.current = setInterval(() => {
        // Ensure the interval does not run infinitely
        if (remainingRuntimeTimer <= 0) {
          clearTimer()
          noStopInterval.current = setTimeout(() => {
            setTimerOutOfSyncWarning(true)
          }, 10000)
          return
        }

        remainingRuntimeTimer -= 1
        options.set(remainingRuntimeTimer)
      }, 1000)
    }

    return false
  }, [])

  useEffect(() => {
    return () => {
      clearTimer()

      if (noStopInterval.current) {
        clearTimeout(noStopInterval.current)
      }
    }
  }, [])

  const statusIsStatic = useMemo(() => {
    return status === 'paused' || status === 'stopped' || status === 'finished'
  }, [status])

  useEffect(() => {
    if (statusIsStatic) {
      // Handle static statuses
      const remainingRuntimeTimer = calculateRuntime({ displayed })
      setSecondsRemaining(remainingRuntimeTimer)

      if (noStopInterval.current) {
        clearTimeout(noStopInterval.current)
        noStopInterval.current = null
      }

      setTimerOutOfSyncWarning(false)
      clearTimer()
    } else if (status !== 'running') {
      // Handle queued or undefined states (show full runtime)
      const fullRuntime = moment.duration(displayed.runtime).asSeconds()
      setSecondsRemaining(fullRuntime)

      if (noStopInterval.current) {
        clearTimeout(noStopInterval.current)
        noStopInterval.current = null
      }

      setTimerOutOfSyncWarning(false)
      clearTimer()
    }

    // Handle running status with ticking timer
    if (status === 'running' && get(displayed, 'timing.shouldTick', false)) {
      createTimer({
        identifyingKey,
        identifyingId,
        displayed,
        set: setSecondsRemaining,
      })
    }
  }, [
    identifyingKey,
    identifyingId,
    get(displayed, 'timing'),
    setSecondsRemaining,
    status,
    intervalId,
  ])
}
