import 'chartjs-adapter-luxon'

import * as Sentry from '@sentry/react'
import classNames from 'classnames'
import { filter, find, get, head, includes, isEmpty, isEqual, map, some, toNumber } from 'lodash-es'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useSetRecoilState } from 'recoil'
import useLocalStorage from 'use-local-storage'

import { Anchor } from '@/Components/form/Buttons'
import Chart from '@/Components/reporting/Chart'
import TopBar from '@/Components/reporting/TopBar'
import { modalState } from '@/Config/Atoms/General'
import { pageAlertState } from '@/Config/Atoms/General'
import { getReportingDashboardOptions } from '@/Utilities/Accessors/ReportingDashboards'
import { formatKeys } from '@/Utilities/Form/Formatter'
import useApiClient from '@/Utilities/useApiClient'
import useAuth from '@/Utilities/useAuth'
import useTitle from '@/Utilities/useTitle'

function Reporting() {
  const apiClient = useApiClient()
  const auth = useAuth()
  const location = useLocation()
  const navigate = useNavigate()
  const urlParams = useParams()

  const [activeDashboard, setActiveDashboard] = useState({})
  const [reportingDashboardOptions, setReportingDashboardOptions] = useState([])
  const [reportingDashboardOptionsLoading, setReportingDashboardOptionsLoading] = useState(false)
  const [searchParams] = useSearchParams()
  const [setupLoading, setSetupLoading] = useState(true)

  const setAlert = useSetRecoilState(pageAlertState)
  const setModal = useSetRecoilState(modalState)

  const [lastViewedDashboardId, setLastViewedDashboardId] = useLocalStorage('lastViewedDashboardId', null)

  useTitle('Reporting')

  const getDashboardOptions = useCallback(async () => {
    try {
      setReportingDashboardOptionsLoading(true)
      const options = await getReportingDashboardOptions(apiClient)
      setReportingDashboardOptions(options)
    } catch (error) {
      Sentry.captureException(error)
    } finally {
      setReportingDashboardOptionsLoading(false)
    }
  }, [])

  const getActiveDashboard = useCallback(async (id) => {
    const currentSearchParams = new URLSearchParams(location.search)
    const startDate = currentSearchParams.get('startDate')
    const endDate = currentSearchParams.get('endDate')
    const query = new URLSearchParams([['with[]', 'charts.dataSets']])

    startDate && query.set('startDate', startDate)
    endDate && query.set('endDate', endDate)

    try {
      setSetupLoading(true)
      let { data } = await apiClient.get(`/reporting-dashboard/view/${id}?${query}`)

      if (data && data.success) {
        data = formatKeys({ ...data }, 'camel')

        setActiveDashboard(data.reportingDashboard)
        setLastViewedDashboardId(id)
      }
    } catch (error) {
      Sentry.captureException(error)

      setLastViewedDashboardId(null)
      setActiveDashboard({})

      setAlert({
        type: 'error',
        content: 'Dashboard not found.',
      })
    } finally {
      setSetupLoading(false)
    }
  }, [urlParams, lastViewedDashboardId])

  const refreshActiveDashboard = useCallback(async () => {
    if (get(activeDashboard, 'id', false)) {
      getActiveDashboard(activeDashboard.id)
    }
  }, [activeDashboard, getActiveDashboard])

  useEffect(() => {
    (async () => {
      const dashboards = await getDashboardOptions()

      if (isEmpty(dashboards)) {
        setSetupLoading(false)
      }
    })()
  }, [])

  useEffect(() => {
    const redirectToId = find(reportingDashboardOptions, ['value', toNumber(lastViewedDashboardId)]) ? lastViewedDashboardId : head(reportingDashboardOptions)?.value

    if (!urlParams.id && redirectToId) {
      navigate(`/reporting/${redirectToId}`)
    } else if (urlParams.id && !isEmpty(reportingDashboardOptions)) {
      const dashboardExists = find(reportingDashboardOptions, ['value', toNumber(urlParams.id)])

      if (dashboardExists) {
        getActiveDashboard(urlParams.id)
      } else {
        if (redirectToId) {
          navigate(`/reporting/${redirectToId}`)
        }
      }
    }
  }, [
    urlParams,
    reportingDashboardOptions,
    lastViewedDashboardId,
  ])

  useEffect(() => {
    const inputOutputIds = searchParams.getAll('inputOutputIds') || []

    if (!isEmpty(inputOutputIds)) {
      const filteredCharts = filter(activeDashboard.charts, (chart) => {
        return some(chart.inputOutputs, (io) => {
          return includes(inputOutputIds, io.id.toString())
        })
      })

      if (!isEqual(activeDashboard.charts, filteredCharts)) {
        setActiveDashboard((prevDashboard) => {
          return {
            ...prevDashboard,
            charts: filteredCharts,
          }
        })
      }
    }
  }, [searchParams, activeDashboard.charts])

  const pageLoading = useMemo(() => {
    return setupLoading
  }, [setupLoading, activeDashboard])

  return (
    <div className={classNames({ 'min-h-reporting-with-top-bar': isEmpty(reportingDashboardOptions) }, 'flex flex-col p-5 pt-0')}>
      <div className="pb-3">
        <TopBar
          getDashboardOptions={getDashboardOptions}
          getActiveDashboard={getActiveDashboard}
          activeDashboard={activeDashboard}
          reportingDashboardOptions={reportingDashboardOptions}
          reportingDashboardOptionsLoading={reportingDashboardOptionsLoading}
          setLastViewedDashboardId={setLastViewedDashboardId}
        />
      </div>

      {pageLoading && (
        <div className="flex min-h-reporting-with-top-bar items-center justify-center">
          <div className="flex items-center justify-center">
            <div className="primary-loader"></div>
          </div>
        </div>
      )}

      {!pageLoading && (
        isEmpty(reportingDashboardOptions) ? (
          <div className="flex grow self-center">
            <div className="flex w-96 flex-col justify-center">
              <div className="text-center">
                <i className="fa-kit fa-sharp-regular-square-kanban-slash fa-8x text-slate-300"></i>
              </div>

              <div className="mt-10 text-center text-3xl">Create a dashboard</div>

              <div className="mt-3.5 text-center text-slate-600">Please add your very first dashboard to begin managing and viewing charts</div>

              <div className={classNames('button-container text-center mt-10', { hidden: !auth.can('create-site') })}>
                <Anchor
                  style={{ width: 'calc(50% - 5px)' }}
                  onClick={() => {
                    setModal({
                      name: 'reportingDashboard',
                      data: {
                        onSave: async (reportingDashboardId) => {
                          await getDashboardOptions()
                          setModal(null)
                          navigate(`/reporting/${reportingDashboardId}`)
                        },
                      },
                    })
                  }}
                >
                  Add dashboard
                </Anchor>
              </div>
            </div>
          </div>
        ) : (
          <>
            {!isEmpty(activeDashboard.charts) ? (
              <div className="min-h-reporting-with-top-bar">
                <div className="grid grid-cols-1 gap-2 @lg:grid-cols-2">
                  {map(activeDashboard.charts, (chart, chartIndex) => {
                    return (
                      <div className="relative w-full items-center justify-center rounded border bg-white p-5 px-2 sm:px-5" key={chartIndex}>
                        <Chart
                          id={chart.id}
                          originalChart={chart}
                          name={chart.name}
                          data={chart.processedDataSets}
                          refreshActiveDashboard={refreshActiveDashboard}
                        />
                      </div>
                    )
                  })}

                  {(activeDashboard?.charts?.length < 10) && (
                    <div className="flex h-full items-center justify-center p-2">
                      <div className="flex min-h-72 w-96 flex-col justify-center">
                        <div className="text-center">
                          <i className="fa-sharp fa-regular fa-chart-column fa-8x text-slate-300"></i>
                        </div>

                        <div className={classNames('button-container text-center mt-10', { hidden: !auth.can('create-site') })}>
                          <Anchor
                            style={{ width: 'calc(50% - 5px)' }}
                            onClick={() => {
                              setModal({
                                name: 'chart',
                                data: {
                                  chart: { reportingDashboardId: activeDashboard.id },
                                  onSave: () => {
                                    getActiveDashboard(activeDashboard.id)
                                  },
                                },
                              })
                            }}
                          >
                            Add chart
                          </Anchor>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ) : !isEmpty(activeDashboard) ? (
              <div className="flex min-h-reporting-with-top-bar items-center justify-center">
                <div className="flex w-96 flex-col justify-center">
                  <div className="text-center">
                    <i className="fa-sharp fa-regular fa-chart-column fa-8x text-slate-300"></i>
                  </div>

                  <div className="mt-10 text-center text-3xl">Add a chart</div>

                  <div className="mt-3.5 text-center text-slate-600">Please add your very first chart to begin monitoring</div>

                  <div className={classNames('button-container text-center mt-10', { hidden: !auth.can('create-site') })}>
                    <Anchor
                      style={{ width: 'calc(50% - 5px)' }}
                      onClick={() => {
                        setModal({
                          name: 'chart',
                          data: {
                            chart: { reportingDashboardId: activeDashboard.id },
                            onSave: () => {
                              getActiveDashboard(activeDashboard.id)
                            },
                          },
                        })
                      }}
                    >
                      Add chart
                    </Anchor>
                  </div>
                </div>
              </div>
            ) : (
              <div className="flex min-h-reporting-with-top-bar items-center justify-center">
                <div className="flex w-96 flex-col justify-center">
                  <div className="text-center">
                    <i className="fa-sharp fa-regular fa-square-kanban fa-8x text-slate-300"></i>
                  </div>

                  <div className="mt-3.5 text-center text-slate-600">Please select a dashboard</div>
                </div>
              </div>
            )}
          </>
        )
      )}
    </div>
  )
}

export default Reporting
