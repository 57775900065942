import { Navigate, Outlet } from 'react-router-dom'

import AuthProvider from '@/Components/auth/AuthProvider'
import AppLayout from '@/Components/layout/AppLayout'
import PublicLayout from '@/Components/layout/PublicLayout'
import UserAuthLayout from '@/Components/layout/UserAuthLayout'
import OrganisationManage from '@/Pages/admin/OrganisationManage'
import UserManage from '@/Pages/admin/UserManage'
import AlarmIndex from '@/Pages/alarm/AlarmsIndex'
import AreaIndex from '@/Pages/area/AreaIndex'
import AreaManage from '@/Pages/area/AreaManage'
import ForgotPassword from '@/Pages/auth/ForgotPassword'
import Login from '@/Pages/auth/Login'
import Logout from '@/Pages/auth/Logout'
import Register from '@/Pages/auth/Register'
import RegisterCompany from '@/Pages/auth/RegisterCompany'
import ResetPassword from '@/Pages/auth/ResetPassword'
import ConditionIndex from '@/Pages/condition/ConditionIndex'
import Dashboard from '@/Pages/Dashboard'
import NoMatch from '@/Pages/error/NoMatch'
import SomethingWentWrong from '@/Pages/error/SomethingWentWrong'
import InputOutputIndex from '@/Pages/inputOutput/InputOutputIndex'
import InputOutputManage from '@/Pages/inputOutput/manage/InputOutputManage'
import MainLineIndex from '@/Pages/mainLine/MainLineIndex'
import MapAll from '@/Pages/map/MapAll'
import ProgramManage from '@/Pages/program/manage/ProgramManage'
import ProgramIndex from '@/Pages/program/ProgramIndex'
import RemoteUnitIndex from '@/Pages/remoteUnit/RemoteUnitIndex'
import Reporting from '@/Pages/Reporting'
import SiteManage from '@/Pages/site/manage/SiteManage'
import SiteIndex from '@/Pages/site/SiteIndex'
import SiteOverview from '@/Pages/site/SiteOverview'
import TermsAndConditions from '@/Pages/TermsAndConditions'
import Profile from '@/Pages/user/Profile'
import TermsAcceptance from '@/Pages/user/TermsAcceptance'

const rootRoute = {
  path: '',
  element: <Navigate to="/user/login" replace={true} />,
}

const publicRoutes = [{
  element: <Outlet />,
  children: [{
    path: '',
    element: <PublicLayout />,
    children: [{
      path: 'legal/terms-and-conditions',
      element: <TermsAndConditions />,
    }],
  }],
}]

const publicAuthRoutes = [{
  element: <Outlet />,
  children: [{
    path: '/user',
    element: <UserAuthLayout />,
    children: [
      {
        path: 'logout',
        element: <Logout />,
      },
      {
        path: 'login',
        element: <Login />,
      },
      {
        path: 'register/company/:hash',
        element: <RegisterCompany />,
      },
      {
        path: 'register/general/:hash',
        element: <Register />,
      },
      {
        path: 'forgot-password',
        element: <ForgotPassword />,
      },
      {
        path: 'reset-password/:hash',
        element: <ResetPassword />,
      },
      {
        path: '',
        element: <Navigate to="/user/login" replace={true} />,
      },
      {
        path: '*',
        element: <Navigate to="/user/login" replace={true} />,
      },
    ],
  }],
}]

const protectedRoutes = [{
  element: <Outlet />,
  children: [{
    path: '/',
    element: <AppLayout />,
    children: [
      {
        path: 'map',
        element: <MapAll />,
      },
      {
        path: 'area/manage/:id',
        element: <AreaManage />,
      },
      {
        path: 'sites',
        element: <SiteIndex />,
      },
      {
        path: 'site/overview/:id',
        element: <SiteOverview />,
      },
      {
        path: 'site/manage/:id',
        element: <SiteManage />,
      },
      {
        path: 'site/manage/:id/coordinates',
        element: <SiteManage manageCoordinates={true} />,
      },
      {
        path: 'site/manage/:id/blocks',
        element: <SiteManage manageBlocks={true} />,
      },
      {
        path: 'remote-units',
        element: <RemoteUnitIndex />,
      },
      {
        path: 'io',
        element: <InputOutputIndex />,
      },
      {
        path: 'io/manage/:id',
        element: <InputOutputManage />,
      },
      {
        path: 'io/manage/:id/coordinates',
        element: <InputOutputManage manageCoordinates={true} />,
      },
      {
        path: 'programs',
        element: <ProgramIndex />,
      },
      {
        path: 'program/manage/:id',
        element: <ProgramManage />,
      },
      {
        path: 'alarms',
        element: <AlarmIndex />,
      },
      {
        path: 'areas',
        element: <AreaIndex />,
      },
      {
        path: 'reporting',
        element: <Reporting />,
      },
      {
        path: 'reporting/:id',
        element: <Reporting />,
      },
      {
        path: 'admin/organization',
        element: <OrganisationManage />,
      },
      {
        path: 'admin/user/manage/:id',
        element: <UserManage />,
      },
      {
        path: 'conditions',
        element: <ConditionIndex />,
      },
      {
        path: 'main-lines',
        element: <MainLineIndex />,
      },
      {
        path: 'dashboard',
        element: <Dashboard />,
      },
      {
        path: 'user/profile',
        element: <Profile />,
      },
      {
        path: 'user/gate/terms-and-conditions',
        element: <TermsAcceptance />,
      },
      {
        path: 'error',
        element: <SomethingWentWrong />,
      },
      {
        path: '*',
        element: <NoMatch />,
      },
    ],
  }],
}]

const routes = [rootRoute, {
  element: <AuthProvider />,
  children: [
    ...publicRoutes,
    ...publicAuthRoutes,
    ...protectedRoutes,
  ],
}]

export default routes
