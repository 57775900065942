import Tippy from '@tippyjs/react'
import classNames from 'classnames'
import { get, head, includes, isEmpty, map } from 'lodash-es'
import React, { useCallback, useMemo, useState } from 'react'
import { useSetRecoilState } from 'recoil'
import { twMerge } from 'tailwind-merge'

import AlertContent from '@/Components/alerts/AlertContent'
import PermissionGuard from '@/Components/auth/PermissionGuard'
import Card from '@/Components/common/Card'
import CurrentDateTime from '@/Components/common/CurrentDateAndTime'
import ProgramSetProgramSwiper from '@/Components/swiper/ProgramSetProgramSwiper'
import ProgramSetTabSwiper from '@/Components/swiper/ProgramSetTabSwiper'
import { modalState, pageAlertState } from '@/Config/Atoms/General'
import useApiClient from '@/Utilities/useApiClient'
import useAuth from '@/Utilities/useAuth'
import usePendingChange from '@/Utilities/usePendingChange'

/**
 * ProgramSetDashboardCard Component
 *
 * @param className
 * @param testId
 * @param title
 * @param programSet
 * @param stateStats
 * @param minTabHeight
 * @param tabIcons
 * @param debug
 * @param refreshing
 * @returns {*|JSX.Element}
 * @constructor
 */
export default function ProgramSetDashboardCard({
  className,
  testId,
  title,
  programSet,
  stateStats,
  minTabHeight = 280,
  tabIcons = [],
  debug = false,
  refreshing = false,
}) {
  const apiClient = useApiClient()
  const auth = useAuth()

  const [programSetStatus, setProgramSetStatus] = useState(null)
  const [activeTabIndex, setActiveTabIndex] = useState(0)
  const setModal = useSetRecoilState(modalState)
  const setAlert = useSetRecoilState(pageAlertState)

  const { PendingChangeStatusIcon } = usePendingChange()

  const cardStatusClassNames = useMemo(() => {
    const mostRecentRecord = head(programSet.operationalStatuses)
    setProgramSetStatus(mostRecentRecord)

    return statusToTailwindMap[mostRecentRecord?.key ?? 'default']
  }, [programSet.operationalStatuses])

  const stateActionResume = useCallback((programSetId) => {
    setModal({
      name: 'warning',
      data: {
        endpoint: `/program-set/state/resume/${programSetId}`,
        title: 'Resume program set',
        content: 'Are you sure you want to resume this set? Program stop times (if set) will still be respected and proceed accordingly. The program set will automatically start again on the next scheduled day.',
        successFlashMessage: `Successfully queued ${programSet.name} to resume.`,
        onComplete: () => {
          if (debug) {
            apiClient.post(`utility/mqtt/program-set/${programSetId}`, { action: 'start' })
          }
          setModal(null)
        },
        close: () => {
          setModal(null)
        },
        onFailure: () => {
          setModal(null)
          setAlert({
            type: 'error',
            content: 'We were unable to process your request. Please try again.',
          })
        },
      },
    })
  }, [])

  const stateActionPause = useCallback((programSetId) => {
    setModal({
      name: 'warning',
      data: {
        endpoint: `/program-set/state/pause/${programSetId}`,
        title: 'Pause program set',
        content: 'Pausing this program set will cause all related programs to pause as well. You have the ability to resume a program set but program stop times (if set) will be respected and proceed accordingly. The program set will automatically start again on the next scheduled day. Do you wish to proceed?',
        successFlashMessage: `Successfully queued ${programSet.name} for pausing.`,
        onComplete: () => {
          setModal(null)
        },
        close: () => {
          setModal(null)
        },
        onFailure: () => {
          setModal(null)
          setAlert({
            type: 'error',
            content: 'We were unable to process your request. Please try again.',
          })
        },
      },
    })
  }, [])

  const stateActionStop = useCallback((programSetId) => {
    setModal({
      name: 'warning',
      data: {
        endpoint: `/program-set/state/stop/${programSetId}`,
        title: 'Stop program set',
        content: 'Stopping this program set will cause all related programs to stop as well. You will not be able to resume the program set, program stop times will be respected and proceed accordingly. The program set will automatically start again on the next scheduled day. Do you wish to proceed?',
        successFlashMessage: `Successfully queued ${programSet?.name} to stop.`,
        onComplete: () => {
          if (debug) {
            apiClient.post(`utility/mqtt/program-set/${programSetId}`, { action: 'stop' })
          }

          setModal(null)
        },
        close: () => {
          setModal(null)
        },
        onFailure: () => {
          setModal(null)
          setAlert({
            type: 'error',
            content: 'We were unable to process your request. Please try again.',
          })
        },
      },
    })
  }, [])

  return (
    <Card
      data-cy={testId}
      data-testid={testId}
      className={classNames(
        className,
        'overflow-hidden rounded-lg',
        { 'grayscale opacity-75': programSet.disabled },
      )}
    >
      <Card.Header
        className={
          twMerge(
            'grid grid-cols-2-right-auto relative rounded-t-lg py-3 px-3 md:py-4 md:px-5',
            cardStatusClassNames?.cardHeader,
          )
        }
        titleClassName={cardStatusClassNames?.title}
        title={(
          <div className="flex items-center">
            <div>
              <PendingChangeStatusIcon
                model={programSet}
                border={true}
                className="mr-3"
              />
            </div>

            <div>
              <div>{title}</div>
              <div className="text-xs font-thin md:text-sm">
                <CurrentDateTime
                  initialTime={get(programSet, 'site.siteTime.utc')}
                  timezone={get(programSet, 'site.timeZone')}
                  showAbbreviation={true}
                />
              </div>
            </div>
          </div>
        )}
      >
        <div className="grid grid-cols-3 items-center justify-center gap-3">
          <div className="flex items-center justify-center">
            {refreshing && (
              <span className="relative flex h-3 w-3 items-center justify-center self-center">
                <span className="animate- absolute inline-flex h-full w-full animate-pulse rounded-full opacity-75 bg-blend-lighten"></span>
                <span className="relative inline-flex h-1.5 w-1.5 rounded-full bg-current"></span>
              </span>
            )}
          </div>

          {map(tabIcons, (tabIcon, tabIconIndex) => {
            return (
              <button
                key={tabIconIndex}
                type="button"
                className={
                  twMerge(
                    'h-8 w-8 items-center justify-center rounded-full bg-transparent',
                    cardStatusClassNames?.tabToolbarIconButtons,
                    activeTabIndex === tabIconIndex
                      ? cardStatusClassNames?.tabToolbarIconButtonsActive
                      : null,
                  )
                }
                onClick={() => {
                  setActiveTabIndex(tabIconIndex)
                }}
              >
                <i className={`fa-solid fa-${tabIcon}`}/>
              </button>
            )
          })}

          {/*
          TODO: Remove this code once we're done with MVP testing on the dashboard.

          NOTE: This code will eventually be removed, but we'll be keeping it here for the time being until we're completely
          finished with MVP testing on the dashboard.

          <DropdownList
            icon={<ActionIcon/>}
            options={[
              {
                label: 'Start first program',
                disabled: false,
                onClick: async () => {
                  apiClient.post(`utility/mqtt/program-set/${programSet.id}`, {
                    action: 'start',
                    program_index: 0,
                  })
                },
              },
              {
                label: 'Start second program',
                disabled: false,
                onClick: async () => {
                  apiClient.post(`utility/mqtt/program-set/${programSet.id}`, {
                    action: 'start',
                    program_index: 1,
                  })
                },
              },
              {
                label: 'Stop all programs',
                onClick: async () => {
                  apiClient.post(`utility/mqtt/program-set/${programSet.id}`, { action: 'stop' })
                },
              },
              {
                label: 'Simulate MainLine Flow',
                onClick: () => {
                  apiClient.post(`utility/mqtt/program-set/${programSet.id}/simulateMainLineFlow`)
                },
              },
              {
                label: 'Simulate Program Set Quantity Update',
                onClick: () => {
                  apiClient.post(`utility/mqtt/program-set/${programSet.id}/simulateProgramSetQuantity`)
                },
              },
            ]}
          />
          */}

        </div>
      </Card.Header>

      <Card.SubHeader>
        <ProgramSetTabSwiper
          debug={debug}
          programSet={programSet}
          status={programSetStatus}
          stateStats={stateStats}
          minTabHeight={minTabHeight}
          activeIndex={activeTabIndex}
          onIndexChange={(index) => {
            setActiveTabIndex(index)
          }}
        />
      </Card.SubHeader>

      <Card.Body>
        <ProgramSetProgramSwiper
          debug={debug}
          programSetId={programSet.id}
          programs={programSet.programs}
          status={programSetStatus}
          irrigationBasis={programSet.irrigationBasis}
          stateStats={stateStats}
        />
      </Card.Body>

      {auth.canAnyOf([
        'start-program',
        'stop-program',
        'pause-program',
      ]) && (
        <Card.Footer>
          {programSet.disabled && (
            <AlertContent type="warning" hideIcon={false} className="m-4" title="Program set disabled">
              You cannot perform actions on a disabled program set. Please enable the program set from the edit modal first.
            </AlertContent>
          )}

          {!programSet.disabled && !isEmpty(programSet.programs) && (
            <div className="my-5 mx-10 grid grid-cols-3 place-items-center gap-5">
              <PermissionGuard anyOf={['start-program', 'stop-program']} auth={auth}>
                <Tippy
                  theme="light"
                  placement="top"
                  content="You can only resume paused program sets."
                  disabled={programSetStatus?.key === 'paused'}
                >
                  <span>
                    <button
                      type="button"
                      className="flex h-20 w-20 items-center justify-center rounded-full border border-slate-200 text-gray-500 hover:bg-slate-50 disabled:bg-slate-50 disabled:text-slate-300 disabled:hover:bg-slate-50"
                      disabled={programSetStatus?.key !== 'paused'}
                      onClick={() => {
                        stateActionResume(programSet.id)
                      }}
                    >
                      <i className="fa-sharp fa-solid fa-play text-3xl" />
                    </button>
                  </span>
                </Tippy>
              </PermissionGuard>

              <PermissionGuard permission="stop-program" auth={auth}>
                <Tippy
                  theme="light"
                  placement="top"
                  content={programSetStatus?.key === 'paused'
                    ? 'You cannot pause this program set as it is already paused.'
                    : 'You cannot pause this program set as it is not running.'}
                  disabled={programSetStatus?.key === 'running'}
                >
                  <span>
                    <button
                      type="button"
                      className="flex h-20 w-20 items-center justify-center rounded-full border border-slate-200 text-gray-500 hover:bg-slate-50 disabled:bg-slate-50 disabled:text-slate-300 disabled:hover:bg-slate-50"
                      disabled={programSetStatus?.key !== 'running'}
                      onClick={() => {
                        stateActionPause(programSet.id)
                      }}
                    >
                      <i className="fa-sharp fa-solid fa-pause text-3xl" />
                    </button>
                  </span>
                </Tippy>

                <Tippy
                  theme="light"
                  placement="top"
                  content="You cannot stop this program set as it is not running."
                  disabled={includes(['running', 'paused'], programSetStatus?.key)}
                >
                  <span>
                    <button
                      type="button"
                      className="flex h-20 w-20 items-center justify-center rounded-full border border-slate-200 text-gray-500 hover:bg-slate-50 disabled:bg-slate-50 disabled:text-slate-300 disabled:hover:bg-slate-50"
                      disabled={!includes(['running', 'paused'], programSetStatus?.key)}
                      onClick={() => {
                        stateActionStop(programSet.id)
                      }}
                    >
                      <i className="fa-sharp fa-solid fa-stop text-3xl" />
                    </button>
                  </span>
                </Tippy>
              </PermissionGuard>

              {/*
              TODO: Remove this code once we're done with MVP testing on the dashboard.

              NOTE: Keeping the following code commented out for the time being as this will be implemented in the future, but
              is not relevant for the MVP.

              <PermissionGuard permission="update-program" auth={auth}>
                <button
                  type="button"
                  className="flex h-20 w-20 items-center justify-center rounded-full border border-slate-200 text-gray-500 hover:bg-slate-50 disabled:bg-slate-50 disabled:text-slate-300 disabled:hover:bg-slate-50"
                  disabled
                >
                  <i className="fa-sharp fa-solid fa-calendar-clock text-3xl" />
                </button>
              </PermissionGuard>
              */}
            </div>
          )}
        </Card.Footer>
      )}
    </Card>
  ) || <></>
}

const statusToTailwindMap = {
  // Default / no status
  default: {
    cardHeader: null,
    title: null,
    tabToolbarIconButtons: 'text-slate-900 hover:bg-slate-200',
    tabToolbarIconButtonsActive: 'text-slate-900 bg-slate-200 hover:bg-slate-200 hover:text-slate-900',
  },

  // Running status
  running: {
    cardHeader: 'bg-status-running text-white border-b-status-running',
    title: 'text-white',
    tabToolbarIconButtons: 'text-white hover:bg-white hover:text-status-running',
    tabToolbarIconButtonsActive: 'text-status-running bg-white hover:bg-white hover:text-status-running',
  },

  // Stopped status
  stopped: {
    cardHeader: 'bg-status-stopped text-white border-b-status-stopped',
    title: 'text-white',
    tabToolbarIconButtons: 'text-white hover:bg-white hover:text-status-stopped',
    tabToolbarIconButtonsActive: 'text-status-stopped bg-white hover:bg-white hover:text-status-stopped',
  },

  // Paused status
  paused: {
    cardHeader: 'bg-status-paused text-white border-b-status-paused',
    title: 'text-white',
    tabToolbarIconButtons: 'text-white hover:bg-white hover:text-status-stopped',
    tabToolbarIconButtonsActive: 'text-status-paused bg-white hover:bg-white hover:text-status-paused',
  },

  // Queued status
  queued: {
    cardHeader: 'bg-status-queued text-white border-b-status-queued',
    title: 'text-white',
    tabToolbarIconButtons: 'text-white hover:bg-white hover:text-status-queued',
    tabToolbarIconButtonsActive: 'text-status-queued bg-white hover:bg-white hover:text-status-queued',
  },
}
