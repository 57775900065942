export default function TermsAndConditions() {
  return (
    <div>
      <h1 className="text-xl font-bold">Software User Agreement (SUA)</h1>
      <p className="mb-4">Last updated: October 14, 2024</p>

      <div className="mb-4">
        <p><strong>Technology Platform - The software:</strong> FutureOps Technology Platform, hereafter referred to as "FutureOps”</p>
        <p><strong>Developer/Publisher/Company:</strong> Branif Systems, Inc. (hereafter referred to as "Branif")</p>
        <p><strong>Headquarters:</strong> 24411 Ridge Route Drive Ste 105, Laguna Hills</p>
      </div>

      <div className="ordered-list">
        <ol>
          <li>
            <div>
              <strong>
                Acceptance of Terms
              </strong>

              <ol>
                <li>By accessing or using the FutureOps software ("Software"), you ("User") acknowledge and agree to comply with and be bound by the terms and conditions set forth in this Software User Agreement ("Agreement"). This Agreement constitutes a legally binding agreement between you and Branif. If you do not agree to these terms, you must cease using the Software immediately.</li>
                <li>Your use of the Software signifies your acceptance of this Agreement, and you acknowledge that you have read, understood, and agree to be bound by all its terms and conditions. This Agreement also incorporates by reference Branif's Privacy Policy and any other policies or guidelines that may be applicable to specific sections of the Software.</li>
                <li>Branif reserves the right to update or modify this Agreement at any time without prior notice. Your continued use of the Software after any such changes signifies your acceptance of the new terms. If you do not agree to the modified terms, you must discontinue your use of the Software.</li>
                <li>You represent and warrant that you are of legal age to form a binding contract and are not a person barred from receiving services under the laws of the United States or other applicable jurisdictions. If you are entering into this Agreement on behalf of a company or other legal entity, you have the authority to bind such entity to this Agreement, in which case the terms "you" or "your" shall refer to such entity.</li>
              </ol>
            </div>
          </li>

          <li>
            <div>
              <strong>
                Grant of License
              </strong>

              <ol>
                <li>Subject to your compliance with the terms of this Agreement, Branif grants you a non-exclusive, non-transferable, revocable, limited license to access and use the Software strictly in accordance with this Agreement. The Software is licensed, not sold, to you by Branif for use strictly in accordance with the terms of this Agreement.</li>
                <li>This license does not allow you to use the Software on any device that you do not own or control, and you may not distribute or make the Software available over a network where it could be used by multiple devices at the same time. You may not rent, lease, lend, sell, redistribute, or sublicense the Software.</li>
                <li>You agree that Branif retains all rights, title, and interest in and to the Software, including all intellectual property rights therein, and reserves any rights not expressly granted to you in this Agreement.</li>
                <li>
                  <div>
                    You may not use the Software for any purpose other than as expressly permitted in this Agreement. You may not:

                    <ol>
                      <li>Copy, modify, or create derivative works of the Software or any part thereof.</li>
                      <li>Decompile, disassemble, reverse engineer, or attempt to discover the source code of the Software.</li>
                      <li>Use the Software in any manner that could damage, disable, overburden, or impair the Software.</li>
                      <li>Use the Software in any manner that could interfere with any other party's use and enjoyment of the Software.</li>
                      <li>Use any robot, spider, or other automated means to access the Software for any purpose without the express written permission of Branif.</li>
                    </ol>
                  </div>
                </li>
                <li>The Software may automatically download and install updates from time to time from Branif. These updates are designed to improve, enhance, and further develop the Software and may take the form of bug fixes, enhanced functions, new software modules, and completely new versions. You agree to receive such updates (and permit Branif to deliver these to you) as part of your use of the Software.</li>
                <li>The license granted to you for the Software by Branif is limited to a non-transferable license to use the Software on any device that you own or control and as permitted by the usage rules set forth in this Agreement.</li>
                <li>The Software is protected by copyright and other intellectual property laws and treaties. Branif owns the title, copyright, and other intellectual property rights in the Software. The Software is licensed, not sold.</li>
              </ol>
            </div>
          </li>

          <li>
            <div>
              <strong>
                Scope of License
              </strong>

              <ol>
                <li>The license granted to you for the Software by Branif is limited to a non-transferable license to use the Software on any device that you own or control and as permitted by the usage rules set forth in this Agreement.</li>
                <li>
                  <div>
                    You may not use the Software for any purpose other than as expressly permitted in this Agreement. You may not:

                    <ol>
                      <li>Copy, modify, or create derivative works of the Software or any part thereof.</li>
                      <li>Decompile, disassemble, reverse engineer, or attempt to discover the source code of the Software.</li>
                      <li>Use the Software in any manner that could damage, disable, overburden, or impair the Software.</li>
                      <li>Use the Software in any manner that could interfere with any other party's use and enjoyment of the Software.</li>
                      <li>Use any robot, spider, or other automated means to access the Software for any purpose without the express written permission of Branif.</li>
                    </ol>
                  </div>
                </li>
                <li>You agree to use the Software in compliance with all applicable laws, rules, and regulations, including any local laws regarding online conduct and acceptable content. You are responsible for all activities that occur under your account.</li>
                <li>The Software may contain links to third-party websites or services that are not owned or controlled by Branif. Branif has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third-party websites or services. You acknowledge and agree that Branif shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods, or services available on or through any such websites or services.</li>
                <li>Branif reserves the right to modify, suspend, or discontinue, temporarily or permanently, the Software or any service to which it connects, with or without notice and without liability to you.</li>
              </ol>
            </div>
          </li>

          <li>
            <div>
              <strong>
                Limitation of Liability
              </strong>

              <ol>
                <li>To the maximum extent permitted by applicable law, Branif shall not be liable for any direct, indirect, incidental, special, consequential, or exemplary damages, including but not limited to, damages for loss of profits, goodwill, use, data, or other intangible losses or resulting in injury or loss of life, arising out of or in connection with your use of or inability to use the Software.</li>
                <li>
                  <div>
                    This limitation of liability applies to any damages arising from:

                    <ol>
                      <li>The use or the inability to use the Software.</li>
                      <li>Unauthorized access to or alteration of your transmissions or data.</li>
                      <li>Statements or conduct of any third party on the Software.</li>
                      <li>Any other matter relating to the Software.</li>
                    </ol>
                  </div>
                </li>
                <li>Some jurisdictions do not allow the exclusion or limitation of liability for consequential or incidental damages, so the above limitation may not apply to you. In such jurisdictions, Branif's liability shall be limited to the fullest extent permitted by law.</li>
                <li>You understand and agree that your use of the Software is at your own discretion and risk and that you will be solely responsible for any damage to your device or loss of data that results from the download or use of the Software. Branif does not guarantee that the Software will be free from loss, corruption, attack, viruses, interference, hacking, or other security intrusions, and Branif disclaims any liability relating thereto.</li>
                <li>Branif shall not be liable for any failure or delay in the performance of its obligations under this Agreement if such failure or delay is caused by circumstances beyond its reasonable control, including but not limited to acts of God, war, terrorism, strikes, internet outages, or government restrictions.</li>
              </ol>
            </div>
          </li>

          <li>
            <div>
              <strong>
                Risk Acceptance
              </strong>

              <ol>
                <li>You acknowledge and agree that the use of the Software carries certain inherent risks, including but not limited to, risks of malfunction, loss of data, hardware failure, and other unforeseen risks. By using the Software, you accept all risks associated with its use.</li>
                <li>Branif makes no representations or warranties of any kind, express or implied, as to the operation of the Software or the information, content, materials, or products included on the Software. To the fullest extent permissible by applicable law, Branif disclaims all warranties, express or implied, including but not limited to, implied warranties of merchantability and fitness for a particular purpose.</li>
                <li>You expressly understand and agree that your use of the Software is at your sole risk and that the Software is provided "as is" and "as available" without warranties of any kind, either express or implied. Branif makes no warranty that the Software will meet your requirements or be available on an uninterrupted, secure, or error-free basis.</li>
                <li>Any material downloaded or otherwise obtained through the use of the Software is done at your own discretion and risk, and you will be solely responsible for any damage to your computer system or loss of data that results from the download of any such material.</li>
                <li>No advice or information, whether oral or written, obtained by you from Branif or through or from the Software shall create any warranty not expressly stated in this Agreement.</li>
                <li>This software can malfunction, and cause property damage, injury, or loss of life - whereby the user acknowledges this risk and Branif accepts no liability for such damages.</li>
              </ol>
            </div>
          </li>

          <li>
            <div>
              <strong>
                Copyright and Intellectual Property
              </strong>

              <ol>
                <li>This Software is the proprietary and copyrighted material of Branif. All right, title, and interest in and to the Software, including all intellectual property rights therein, are and shall remain the exclusive property of Branif. The Software is protected by copyright, trademark, and other laws of both the United States and foreign countries. Unauthorized use of the Software is strictly prohibited.</li>
                <li>
                  <div>
                    You agree not to, and you will not permit others to:

                    <ol>
                      <li>License, sell, rent, lease, assign, distribute, transmit, host, outsource, disclose, or otherwise commercially exploit the Software or make the Software available to any third party.</li>
                      <li>Copy or use the Software for any purpose other than as permitted in this Agreement.</li>
                      <li>Modify, make derivative works of, disassemble, reverse compile, or reverse engineer any part of the Software.</li>
                    </ol>
                  </div>
                </li>
                <li>The trademarks, service marks, and logos used and displayed in the Software are Branif's registered and unregistered trademarks or those of Branif's licensors. You may not use these trademarks, service marks, or logos in any way without the prior written permission of Branif or the relevant trademark owner.</li>
                <li>You acknowledge and agree that any feedback, comments, or suggestions you may provide regarding the Software ("Feedback") will be the sole and exclusive property of Branif, and you hereby irrevocably assign to Branif all of your rights, title, and interest in and to all Feedback, including all intellectual property rights therein.</li>
              </ol>
            </div>
          </li>

          <li>
            <div>
              <strong>
                Data Accuracy and Disclaimer
              </strong>

              <ol>
                <li>Branif does not warrant that the data provided by the Software is accurate, complete, reliable, current, or error-free. All data and information provided by the Software are provided "as is" and "as available" without any warranties of any kind, either express or implied. Use of the data and information provided by the Software is at your own risk.</li>
                <li>Branif makes no representation or warranty of any kind, express or implied, as to the operation of the Software or the information, content, materials, or products included on the Software. You expressly agree that your use of the Software is at your sole risk.</li>
                <li>The information and data presented in the Software are for informational purposes only and should not be considered as professional, legal, financial, or other advice. You should consult appropriate professionals for advice specific to your situation.</li>
                <li>Branif makes no warranty that the information provided through the Software will meet your requirements or that the Software will be uninterrupted, timely, secure, or error-free.</li>
              </ol>
            </div>
          </li>

          <li>
            <div>
              <strong>
                Personal Data
              </strong>

              <ol>
                <li>The data you provide to FutureOps is used strictly to deliver the services offered by the Software. The data collected from the platform is the end user's data and is not used for any other purpose. Branif does not sell, rent, lease, or otherwise market your data to third parties under any circumstances.</li>
                <li>You agree that Branif may collect and use technical data and related information, including but not limited to technical information about your device, system and application software, and peripherals, that is gathered periodically to facilitate the provision of software updates, product support, and other services to you (if any) related to the Software.</li>
                <li>Branif may use this information, as long as it is in a form that does not personally identify you, to improve its products or to provide services or technologies to you.</li>
                <li>Branif respects your privacy and is committed to protecting your personal data. By using the Software, you consent to the collection, use, and disclosure of your personal data in accordance with this Agreement and Branif's Privacy Policy.</li>
                <li>You are responsible for maintaining the confidentiality of your account information, including your username and password, and for any and all activity that occurs under your account. You agree to notify Branif immediately of any unauthorized use of your account or password, or any other breach of security.</li>
              </ol>
            </div>
          </li>

          <li>
            <div>
              <strong>
                Privacy Policy
              </strong>

              <ol>
                <li>Your use of the Software is also governed by our Privacy Policy, which is incorporated by reference into this Agreement. Please review our Privacy Policy at [Privacy Policy URL] for more information on how we collect, use, and share information about you.</li>
                <li>By using the Software, you consent to the collection and use of your information as outlined in our Privacy Policy.</li>
                <li>Branif reserves the right to modify its Privacy Policy at any time, and any changes will be effective immediately upon posting on the Software. Your continued use of the Software after the posting of any changes to the Privacy Policy constitutes your acceptance of those changes.</li>
                <li>Branif will take reasonable measures to protect your personal information from unauthorized access, use, or disclosure. However, you acknowledge and agree that no system or network is completely secure, and Branif does not guarantee the security of your information.</li>
                <li>You have the right to access, correct, or delete your personal information that Branif holds. To exercise these rights, please contact Branif at [Contact Information].</li>
              </ol>
            </div>
          </li>

          <li>
            <div>
              <strong>
                Payment Terms
              </strong>

              <ol>
                <li>FutureOps is a Software as a Service (SaaS) product. Customers must ensure their accounts are in good standing, including timely payment of all applicable fees. Branif reserves the right to restrict, suspend, or terminate access to FutureOps if the account is not in good standing.</li>
                <li>You agree to pay all fees or charges to your account in accordance with the fees, charges, and billing terms in effect at the time a fee or charge is due and payable. By providing Branif with your payment information, you agree that Branif is authorized to immediately invoice your account for all fees and charges due and payable to Branif hereunder and that no additional notice or consent is required.</li>
                <li>If your payment method fails or your account is past due, Branif reserves the right to either suspend or terminate your access to the Software. If your account is suspended, a reactivation fee may apply. You agree to pay any costs associated with the reactivation of your account.</li>
                <li>Branif reserves the right to change its fees and billing methods at any time, either immediately upon posting on the Software or by email delivery to you.</li>
                <li>All fees are exclusive of all taxes, levies, or duties imposed by taxing authorities, and you shall be responsible for payment of all such taxes, levies, or duties.</li>
                <li>If you believe that Branif has billed you incorrectly, you must contact Branif no later than 30 days after the date of the first billing statement in which the error or problem appeared, in order to receive an adjustment or credit. Inquiries should be directed to Branif's customer support department.</li>
                <li>Branif may offer free trials or promotional offers for the Software. If you do not cancel your subscription before the end of the trial period, you authorize Branif to charge your payment method for the Software.</li>
                <li>All sales are final, and Branif does not offer refunds for any services or products purchased through the Software, except as required by law.</li>
              </ol>
            </div>
          </li>

          <li>
            <div>
              <strong>
                Termination
              </strong>

              <ol>
                <li>This Agreement is effective until terminated by you or Branif. Your rights under this Agreement will terminate automatically without notice from Branif if you fail to comply with any term(s) of this Agreement. Upon termination of this Agreement, you shall cease all use of the Software and destroy all copies, full or partial, of the Software.</li>
                <li>Branif may, at its sole discretion, at any time and for any reason, terminate this Agreement with or without prior notice.</li>
                <li>Upon termination of this Agreement, you shall cease all use of the Software and destroy all copies, full or partial, of the Software. In the event of termination, sections of this Agreement which by their nature should survive termination shall survive termination, including, but not limited to, ownership provisions, warranty disclaimers, indemnity, and limitations of liability.</li>
                <li>Termination of this Agreement will not limit any of Branif's rights or remedies at law or in equity in case of breach by you of any of your obligations under this Agreement.</li>
              </ol>
            </div>
          </li>

          <li>
            <div>
              <strong>
                Amendments to this Agreement
              </strong>

              <ol>
                <li>Branif reserves the right, at its sole discretion, to modify or replace this Agreement at any time. If a revision is material, we will provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion. Your continued use of the Software following the posting of any changes to this Agreement signifies your acceptance of those changes.</li>
                <li>It is your responsibility to review this Agreement periodically for changes. If you do not agree to any changes, you must immediately stop using the Software. Branif will not be liable for any failure to notify you of any changes or modifications to this Agreement.</li>
                <li>Any modifications or changes to this Agreement will be effective immediately upon posting on the Software. Your continued use of the Software after such modifications or changes will constitute your acceptance of the new terms.</li>
              </ol>
            </div>
          </li>

          <li>
            <div>
              <strong>
                Governing Law
              </strong>

              <ol>
                <li>This Agreement shall be governed by and construed in accordance with the laws of the State of California, without regard to its conflict of law principles. You agree to submit to the personal jurisdiction of the state and federal courts located in Orange County, California, for the resolution of any disputes arising out of or in connection with this Agreement or the Software.</li>
                <li>You agree that any cause of action arising out of or related to the Software must commence within one (1) year after the cause of action accrues. Otherwise, such cause of action is permanently barred.</li>
                <li>You agree to resolve any disputes arising under or related to this Agreement through binding arbitration in Orange County, California. The arbitration shall be conducted by the American Arbitration Association under its Commercial Arbitration Rules.</li>
              </ol>
            </div>
          </li>

          <li>
            <div>
              <strong>
                Indemnification
              </strong>

              <ol>
                <li>You agree to indemnify, defend, and hold harmless Branif and its affiliates, officers, directors, employees, agents, and licensors from and against any and all claims, liabilities, damages, losses, costs, expenses, or fees (including reasonable attorneys' fees) that such parties may incur as a result of or arising from your (or anyone using your account's) violation of this Agreement. Branif reserves the right to assume the exclusive defense and control of any matter otherwise subject to indemnification by you.</li>
                <li>This indemnification obligation will survive the termination or expiration of this Agreement and your use of the Software.</li>
                <li>You agree to cooperate fully with Branif in the defense of any claim. You agree to notify Branif immediately of any unauthorized use of your account or any other breach of security.</li>
              </ol>
            </div>
          </li>

          <li>
            <div>
              <strong>
                Entire Agreement
              </strong>

              <ol>
                <li>This Agreement constitutes the entire agreement between you and Branif regarding the use of the Software and supersedes all prior and contemporaneous written or oral agreements, proposals, negotiations, representations, or communications between you and Branif regarding the subject matter herein.</li>
                <li>If any provision of this Agreement is found to be invalid or unenforceable, the remaining provisions will continue in full force and effect. The failure of either party to enforce any right or provision of this Agreement will not be deemed a waiver of such right or provision.</li>
                <li>This Agreement may not be assigned by you without the prior written consent of Branif, and any attempted assignment in violation of this Agreement will be null and void. Branif may assign this Agreement to any third party without your consent.</li>
              </ol>
            </div>
          </li>

          <li>
            <div>
              <strong>
                Notices
              </strong>

              <ol>
                <li>All notices, requests, demands, and determinations for Branif under this Agreement (other than routine operational communications) shall be sent to: Branif Systems, Inc., Attn: Legal Department, 24411 Ridge Route Drive Ste 105, Laguna Hills, California.</li>
              </ol>
            </div>
          </li>

          <li>
            <div>
              <strong>
                Acknowledgement
              </strong>

              <ol>
                <li>By accessing or using the Software, you acknowledge that you have read this Agreement, understand it, and agree to be bound by its terms and conditions.</li>
              </ol>
            </div>
          </li>

          <li>
            <div>
              <strong>
                Inherent Risks and Third-Party Systems
              </strong>

              <ol>
                <li>The Software has inherent risks beyond our control. It connects to and operates hydraulic systems that were not audited or verified by our engineers. Further, it relies upon sensors, control systems, such as pumps, valves, pipelines, wells, chemical injection systems, and fertilizer systems that Branif does not control to verify if they are functional.</li>
                <li>Therefore, we cannot guarantee that our system will not fail to operate, monitor, or control such operations. It is the responsibility of the supplier of those systems that FutureOps connects to ensure their correct operational status. If these systems fail as a result of FutureOps not responding or operating correctly, Branif does not accept any liability for such actions or inactions.</li>
                <li>We design our software to hand off the responsibility to these third-party systems and may provide feedback on such systems' operational status, such as in the case of utilizing an API or Modbus. However, the user must verify on their own that such integration, control, and management are in working order and that the appropriate safety and redundancy measures are in place.</li>
                <li>By using the Software, you acknowledge and agree that Branif cannot be held responsible for any failures, malfunctions, or operational issues arising from third-party systems, components, or equipment that interact with or are controlled by the Software. The ultimate responsibility for ensuring the proper functioning and safety of such systems lies with the respective suppliers and users of those systems.</li>
              </ol>
            </div>
          </li>

          <li>
            <div>
              <strong>
                Miscellaneous
              </strong>

              <ol>
                <li>This Agreement constitutes the entire agreement between you and Branif and governs your use of the Software, superseding any prior agreements between you and Branif with respect to the Software.</li>
                <li>The failure of Branif to exercise or enforce any right or provision of this Agreement shall not constitute a waiver of such right or provision. If any provision of this Agreement is found by a court of competent jurisdiction to be invalid, the parties nevertheless agree that the court should endeavour to give effect to the parties' intentions as reflected in the provision, and the other provisions of this Agreement remain in full force and effect.</li>
                <li>You agree that regardless of any statute or law to the contrary, any claim or cause of action arising out of or related to the use of the Software or this Agreement must be filed within one (1) year after such claim or cause of action arose or be forever barred.</li>
                <li>The section titles in this Agreement are for convenience only and have no legal or contractual effect.</li>
                <li>No waiver by either party of any breach or default hereunder shall be deemed to be a waiver of any preceding or subsequent breach or default.</li>
                <li>This Agreement is not assignable, transferable, or sublicensable by you except with Branif's prior written consent, but may be assigned or transferred by Branif without restriction. Any assignment attempted to be made in violation of this Agreement shall be void.</li>
                <li>The terms of this Agreement are binding upon and will inure to the benefit of the parties, their successors, and permitted assigns.</li>
                <li>You acknowledge and agree that any violation of the terms of this Agreement may cause irreparable harm to Branif, for which monetary damages would be inadequate, and you consent to Branif obtaining any injunctive or equitable relief that Branif deems necessary or appropriate in such circumstances. These remedies are in addition to any other remedies Branif may have at law or in equity.</li>
                <li>This Agreement does not create any agency, partnership, joint venture, or employment relationship, and you may not make any representations or bind Branif in any manner.</li>
              </ol>
            </div>
          </li>

          <li>
            <div>
              <strong>
                No Warranty and "As-Is" Provision
              </strong>

              <ol>
                <li>The Software is provided on an "as-is" and "as-available" basis, with all faults and without warranty of any kind. Branif expressly disclaims all warranties and conditions, whether express, implied, or statutory, including but not limited to, any implied warranties of merchantability, fitness for a particular purpose, title, non-infringement, and any warranties arising out of course of dealing or usage of trade.</li>
                <li>Branif does not warrant that the Software will meet your requirements, be uninterrupted, secure, or error-free, or that defects in the Software will be corrected. Branif makes no representations or warranties regarding the use or the results of the use of the Software in terms of its correctness, accuracy, reliability, or otherwise.</li>
                <li>No oral or written information or advice given by Branif or its authorised representatives shall create a warranty. You expressly acknowledge and agree that the use of the Software is at your sole risk.</li>
                <li>Some jurisdictions do not allow the exclusion of certain warranties or limitations on applicable statutory rights of a consumer, so the above exclusions and limitations may not apply to you. In such cases, any implied warranties are limited to thirty (30) days from the date of your first use of the Software.</li>
              </ol>
            </div>
          </li>
        </ol>
      </div>
    </div>
  )
}
