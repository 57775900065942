import { Outlet } from 'react-router-dom'

export default function PublicLayout() {
  return (
    <div className="h-screen w-screen bg-white md:bg-layout-bg-image md:bg-cover md:bg-center">
      <div className="grid h-full grid-cols-12 items-center justify-center overflow-y-auto md:backdrop-brightness-75">
        <div className="col-span-12 md:col-span-10 md:col-start-2">
          <div className="max-w-md">
            <img src="/images/logos/branif/logo.svg" alt="Branif Logo" className="hidden w-6/12 py-8 md:mx-0 md:flex"/>
            <img src="/images/logos/branif/logo-invert.svg" alt="Branif Logo" className="mt-8 ml-6 mb-4 w-6/12 md:mx-0 md:hidden"/>
          </div>

          <div className="mb-12 bg-white p-6 md:p-10 lg:rounded">
            <Outlet className="main-container"/>
          </div>
        </div>
      </div>
    </div>
  )
}
