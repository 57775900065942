import { mapValues } from 'lodash-es'

import { faIcon } from '@/Utilities/Icons/FaIcon'
import { mapIcon } from '@/Utilities/Icons/MapIcon'

export const iconClasses = {
  analogInput: 'fa-solid fa-gauge-simple-max',
  analogOutput: 'fa-sharp fa-solid fa-square-sliders',
  digitalInput: 'fa-sharp fa-solid fa-toggle-on',
  digitalOutput: 'fa-sharp fa-solid fa-toggle-on',
  analogFlowMeter: 'fa-solid fa-gauge-low',
  digitalFlowMeter: 'fa-solid fa-gauge-low',
  digitalOutputBooster: 'fa-sharp fa-solid fa-pump',
  digitalOutputElectricLock: 'fa-sharp fa-solid fa-outlet',
  digitalOutputFountain: 'fa-solid fa-sprinkler',
  digitalOutputGeneral: 'fa-sharp fa-solid fa-square-bolt',
  digitalOutputLight: 'fa-sharp fa-solid fa-lightbulb',
  digitalOutputValve: 'fa-sharp fa-solid fa-pipe-valve',
  virtualDigitalOutput: 'fa-solid fa-code-compare',
  sensorSoilProbe: 'fa-duotone fa-tree-deciduous rotate-180',
  sensorGeneralDigital: 'fa-solid fa-wave-square',
}

export const icons = mapValues(iconClasses, (className) => {
  return (options = {}) => {
    options = {
      ...options,
      faClass: className,
    }

    return faIcon(options)
  }
})

export const mapIcons = mapValues(iconClasses, (className) => {
  return (options = {}) => {
    const { overrideClass } = options

    options = {
      ...options,
      width: 40,
      height: 40,
      faClass: className,
      iconClass: 'drop-shadow-lg',
      wrapperClass: `flex items-center justify-center rounded-full ${overrideClass ? overrideClass : 'bg-slate-500'} border-2 text-lg text-white`,
    }

    return mapIcon(options)
  }
})
