import '@/Utilities/Form/Yup'

import { yupResolver } from '@hookform/resolvers/yup'
import * as Sentry from '@sentry/react'
import { filter, find, get, includes, isEmpty, isFunction, map, orderBy, some, times } from 'lodash-es'
import { useEffect, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useRecoilState, useSetRecoilState } from 'recoil'
import * as yup from 'yup'

import { Anchor, Button } from '@/Components/form/Buttons'
import Input from '@/Components/form/Input'
import InputError from '@/Components/form/InputError'
import Select from '@/Components/form/Select'
import Modal from '@/Components/Modal'
import { isLoadingState, pageAlertState } from '@/Config/Atoms/General'
import { toOptions } from '@/Utilities/Form/Formatter'
import useApiClient from '@/Utilities/useApiClient'

const requireGaugeType = (inputOutputType) => {
  if (!inputOutputType) {
    return false
  }

  return !some([
    'digitalOutput',
    'virtualDigitalOutput',
    'digitalInput',
  ], (type) => {
    return includes(inputOutputType, type)
  })
}

const schema = yup.object({
  name: yup.string().label('Name').required().min(3).max(50),
  inputOutputId: yup.mixed().label('Element').populatedObject(),
  gaugeType: yup.mixed().label('Gauge type').when('inputOutputId', ([inputOutputId], schema) => {
    if (requireGaugeType(inputOutputId.detailsType)) {
      return schema.populatedObject()
    }

    return schema
  }),
  sensorLevels: yup
    .array()
    .of(
      yup
        .mixed().populatedObject(),
    )
    .max(4, 'You can select up to 4 sensor levels only')
    .when('inputOutputId', {
      is: (inputOutputId) => {
        return inputOutputId?.detailsType === 'inputOutput.sensorSoilProbe'
      },
      then: (schema) => {
        return schema.required('Please select a maximum of 4 sensor levels').min(1, 'Select at least one sensor level')
      },
      otherwise: (schema) => {
        return schema.notRequired()
      },
    }),
})

export default function CardModal(props) {
  const apiClient = useApiClient()
  const [isLoading, setIsLoading] = useRecoilState(isLoadingState)
  const setAlert = useSetRecoilState(pageAlertState)
  const {
    isEditing,
    site,
    siteCard,
  } = props.data

  const sensorLabel = (depth) => {
    return `Sensor level ${depth}`
  }

  const inputOutputOptions = useMemo(() => {
    if (isEmpty(site.inputOutputs)) {
      return []
    }

    const inputOutputsWithChangeOfState = filter(site.inputOutputs, ['changeOfState', true])

    return toOptions(inputOutputsWithChangeOfState, ['name', 'label'], ['id', 'value'], ['detailsType', 'detailsType'], ['details.manufacturerModel.configuration.depths', 'depths'])
  }, [site])

  const indicatorTypes = [
    {
      label: 'Full circle',
      value: 'fullCircle',
    },
    {
      label: 'Half circle',
      value: 'halfCircle',
    },
    {
      label: 'Horizontal line',
      value: 'horizontalLine',
    },
    {
      label: 'Vertical line',
      value: 'verticalLine',
    },
  ]

  const {
    reset,
    register,
    handleSubmit,
    watch,
    setValue,
    formState,
    control,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      inputOutputId: find(inputOutputOptions, ['value', get(siteCard, 'inputOutputId', '')]) || '',
      name: get(siteCard, 'name', ''),
      gaugeType: find(indicatorTypes, ['value', get(siteCard, 'gaugeType', '')]) || '',
      sensorLevels: map(get(siteCard, 'selectedSensorLevels', []), (option) => {
        return {
          label: sensorLabel(option),
          value: option,
        }
      }) || [],
    },
  })
  const { errors } = formState
  const selectedInputOutput = watch('inputOutputId')

  useEffect(() => {
    if (!requireGaugeType(get(selectedInputOutput, 'detailsType', false))) {
      setValue('gaugeType', '')
    }
  }, [selectedInputOutput])

  const isSensorSoilProbe = useMemo(() => {
    return get(selectedInputOutput, 'detailsType') === 'inputOutput.sensorSoilProbe'
  }, [selectedInputOutput])

  const depthOptions = useMemo(() => {
    if (isSensorSoilProbe) {

      return times(get(selectedInputOutput, 'depths', 0), (depth) => {
        return {
          label: sensorLabel(depth + 1),
          value: depth + 1,
        }
      })
    }

    return []
  }, [selectedInputOutput, isSensorSoilProbe])

  const indicatorTypeOptions = useMemo(() => {
    if (get(selectedInputOutput, 'detailsType', false) === 'inputOutput.sensorSoilProbe') {
      return filter(indicatorTypes, (type) => {
        return type.value !== 'fullCircle' && type.value !== 'halfCircle'
      })
    }

    return indicatorTypes
  }, [indicatorTypes, selectedInputOutput])

  const onSubmit = async (data) => {
    try {
      setAlert(null)
      setIsLoading(true)

      let payload = {
        site_id: site.id,
        input_output_id: data.inputOutputId.value,
        name: data.name,
      }

      if (requireGaugeType(get(selectedInputOutput, 'detailsType', false))) {
        payload.gauge_type = data.gaugeType.value
      }

      if (get(selectedInputOutput, 'detailsType', false) === 'inputOutput.sensorSoilProbe') {
        payload.selected_sensor_levels = orderBy(map(data.sensorLevels, (sensorLevel) => {
          return sensorLevel.value
        }))
      }

      let { data: responseData } = await apiClient.post(`${isEditing ? `/site-card/update/${get(props, 'data.siteCard.id', null)}` : '/site-card/create'}`, payload)

      if (responseData.success) {
        if (isFunction(props.data?.onSave)) {
          props.data.onSave()
        }

        setAlert({
          type: 'success',
          content: `${responseData.siteCard.name} has been successfully ${isEditing ? 'updated' : 'created'}.`,
        })

        if (isFunction(props.close)) {
          props.close()
        }
      }
    } catch (error) {
      Sentry.captureException(error)
      setAlert({
        type: 'error',
        content: 'An error has occured and we were not able to save this card. Please try again.',
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Modal
      title={`${isEditing ? 'Edit' : 'Add' } card`}
      close={props.close}
      closeOnOutsideClick={props.closeOnOutsideClick}
    >
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <Input
          name="name"
          label="Name"
          id="name"
          isRequired={true}
          className={errors.name && 'border-red-400 focus:outline-1 focus:outline-red-400'}
          {...register('name', { value: get(props, 'data.siteCard.name', '') })}
        />
        {errors.name && <InputError message={errors.name.message} />}

        {
          inputOutputOptions &&
          <div className="mt-5">
            <Controller
              control={control}
              name="inputOutputId"
              render={({ field }) => {
                return (
                  <Select
                    {...field}
                    helpTooltip="Please note: Selection is restricted to I/Os with reporting enabled."
                    isMulti={false}
                    isSearchable={true}
                    isRequired={true}
                    label="Element"
                    id="inputOutputId"
                    options={inputOutputOptions}
                    placeholder="Search"
                    hasError={!!errors.inputOutputId}
                  />
                )
              }}
            />
            {errors.inputOutputId && <InputError message={errors.inputOutputId.message} />}
          </div>
        }

        {isSensorSoilProbe && (
          <div className="mt-5">
            <Controller
              control={control}
              name="sensorLevels"
              render={({ field }) => {
                return (
                  <Select
                    {...field}
                    helpTooltip="Select up to 4 sensor levels to display. Once the maximum is reached, you won’t be able to select additional sensors. To adjust your selection, deselect one of the chosen options before adding another."
                    isMulti={true}
                    isSearchable={true}
                    isRequired={true}
                    label="Sensors to display"
                    options={depthOptions}
                    hasError={!!errors.sensorLevels}
                  />
                )
              }}
            />
            {errors.sensorLevels && <InputError message={errors.sensorLevels.message} />}
          </div>
        )}

        {requireGaugeType(get(selectedInputOutput, 'detailsType', false)) && (
          <div className="mt-5">
            <Controller
              control={control}
              name="gaugeType"
              render={({ field }) => {
                return (
                  <Select
                    {...field}
                    isMulti={false}
                    isSearchable={true}
                    isRequired={true}
                    label="Type"
                    options={indicatorTypeOptions}
                    hasError={!!errors.gaugeType}
                  />
                )
              }}
            />
            {errors.gaugeType && <InputError message={errors.gaugeType.message} />}
          </div>
        )}

        <div className="mt-10 flex justify-between">
          <Anchor
            style={{ width: 'calc(50% - 5px)' }}
            className="transparent"
            onClick={() => {
              setAlert(null)
              reset()

              props.close()
            }}
          >
            Close
          </Anchor>

          <Button
            style={{ width: 'calc(50% - 5px)' }}
            disabled={isLoading ? true : false}
          >
            {isLoading ? <div className="primary-loader light"></div> : 'Save'}
          </Button>
        </div>
      </form>
    </Modal>
  )
}
